<h1>{{ page.totalElements }} orders</h1>


<div class="row">

	<div class="col-12">
		<div class="container-fluid mt-4 mb-4" *ngIf="!isLoading && page.size < page.currentTotalElements">
			<app-pagination [page]="page" [list]="orders" (init)="initOrders($event)"></app-pagination>
		</div>
	</div>

	<div class="col-12" *ngIf="!isLoading">
		<div class="card mb-3" *ngFor="let order of orders">
			<div class="card-header">
				<div class="row">
					<div class="col-6">
						<h3>
							<i class="fa fa-calendar"></i> {{ order.created_at.date | date:'d MMMM, h:mm a' }}
						</h3>
						<small>Order Id: {{ order.cart_id }}</small>
					</div>
					<div class="col-6 text-end">
						<span class="badge bg-light me-2 text-dark">{{ order.cart_products.length }} products</span>
        		<span class="badge bg-dark me-2">{{ order.payment_status }}</span>
						<span class="badge bg-success me-2">{{ order.price_total }} €</span>
						<span class="badge bg-secondary me-2">{{ order.delivery_status }}</span>

						<button class="btn btn-secondary" *ngIf="order.payment_status == 'done'" (click)="downloadInvoice(order.id)"><i class="fa fa-download"></i> INVOICE</button>
						<button class="btn btn-secondary" (click)="openEditOrdersModal(order)"><i class="fa fa-eye"></i> Details</button>
					</div>
				</div>
			</div>
			<div class="card-body">
				<div class="col-12">
					{{ order.firstname + ' ' + order.lastname + ', '+ order.street + ', ' + order.zip_code + ' ' + order.city + ' ' +  order.country + ', ' + order.phone }}, {{ order.extra }}
				</div>

        <div *ngFor="let product of order.cart_products" class="card mt-3">
					<div class="card-body">
						<div class="row">
							<div class="col-2"><img class="img-fluid" width="50px" src="{{ product.image }}" /></div>
							<div class="col-5">
								<b>{{ product.name }}</b> <br />
								<span class="text-muted">{{ product.price }}€ / {{ product.gramme }} Gramme{{ product.gramme > 1 ? 's' : ''}}</span>
							</div>
							<div class="col-2">
								{{ product.gramme * product.quantity }}G
							</div>
							<div class="col-2 text-right"><span class="text-nowrap">{{ (product.price * product.quantity) }} €</span></div>

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="col-12">
		<div class="container-fluid mt-4 mb-4" *ngIf="!isLoading && page.size < page.currentTotalElements">
			<app-pagination [page]="page" [list]="orders" (init)="initOrders($event)"></app-pagination>
		</div>
	</div>
</div>
<div *ngIf="isLoading"  class="spinner-ctnr">
	<div class="logo-fixed"></div>
	<div class="spinner colorwheel">
	<div class="centerpiece"></div>
</div>
