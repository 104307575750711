import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from '../../users.service';
import { ToastrService } from 'ngx-toastr';
import { webConfig } from '../../../../web-config';
import { FormModalCore } from '../../../../_/core/form-modal.core';

@Component({
  selector: 'tpc-users-modal-edit',
  templateUrl: './users-modal-edit.component.html',
  styleUrls: ['./users-modal-edit.component.scss']
})
export class UsersModalEditComponent extends FormModalCore implements OnInit {
	modelId: any = 0;

  model: any = {
    data: {}
  };

  birthdayModel: NgbDateStruct = {
    day: 1,
    month: 1,
    year: 2002
  };

  subjectRoles;
  roles: any[] = [];

  errors: any = {}
  errorsMessage: string = '';

  constructor(
  	public bsModalRef: BsModalRef,
  	private usersService: UsersService,
  	private toastrService: ToastrService) {
    super();
  }

  ngOnInit(): void {
  	this._initRoles();
  }

  private _initRoles() {
    this.subjectRoles = this.usersService.getAllRoles().subscribe((roles: any) => {
      if (roles.content) {
        this.roles = roles.content;
      }
    });

    if (this.model.data.birthday) {
      let date = this.model.data.birthday.split('-');
      this.birthdayModel.day = parseInt(date[2]);
      this.birthdayModel.month = parseInt(date[1]);
      this.birthdayModel.year = parseInt(date[0]);
    }
  }

  cancel() {
		this.model = {
      data: {}
    };

    this.bsModalRef.hide();
  }

	save() {
    this.startLoading();

    this.model.data.birthday = `${this.birthdayModel.year}-${this.birthdayModel.month}-${this.birthdayModel.day}`;

    this.usersService.updateUser(this.modelId, this.model).subscribe((data: any) => {
      if (!data.errors) {
        this.cancel();
        this.usersService.onUserUpdated.emit(true);
        this.toastrService.success('User updated!');
      } else {
      	this.errors = data.errors;
        this.toastrService.error(data.message || 'An error has occurred');
      }
  	}, error => this.toastrService.error('An error has occurred'));
  }
}
