<div class="row">
	<div class="col-12 text-center">
		<nav class=" mt-4" *ngIf="list && list.length" aria-label="...">
		  <ul class="pagination pagination-lg justify-content-center">
		    <li class="page-item" [class.disabled]="page.pageNumber == 1">
		      <button class="page-link" (click)="setFirstPage()">{{ '#First' | dgTranslate }}</button>
		    </li>
		    <li class="page-item" [class.disabled]="page.pageNumber == 1">
		      <button class="page-link" (click)="previousPage()">{{ '#Previous' | dgTranslate }}</button>
		    </li>
		     <li class="page-item" *ngIf="page.pageNumber - 2 >= 1">
		      <button class="page-link" (click)="setPositionPage(page.pageNumber - 2)">{{ page.pageNumber - 2 }}</button>
		    </li>
		    <li class="page-item" *ngIf="page.pageNumber - 1 >= 1 ">
		      <button class="page-link" (click)="setPositionPage(page.pageNumber - 1)">{{ page.pageNumber - 1 }}</button>
		    </li>
		    <li class="page-item active">
		      <button class="page-link">{{ page.pageNumber }}</button>
		    </li>
		    <li class="page-item" *ngIf="page.totalPage - 1 >= page.pageNumber">
		      <button class="page-link" (click)="setPositionPage(page.pageNumber + 1)">{{ page.pageNumber + 1 }}</button>
		    </li>
		    <li class="page-item" *ngIf="page.totalPage - 2 >= page.pageNumber">
		      <button class="page-link" (click)="setPositionPage(page.pageNumber + 2)">{{ page.pageNumber + 2 }}</button>
		    </li>
		    <li class="page-item" [class.disabled]="page.pageNumber == page.totalPage || page.totalPage - page.pageNumber - 2 < 1">
		      <button class="page-link" (click)="nextPage()">{{ '#Next' | dgTranslate }}</button>
		    </li>
		    <li class="page-item" [class.disabled]="page.pageNumber == page.totalPage || page.totalPage - page.pageNumber - 2 < 1">
		      <button class="page-link" (click)="setLastPage()">{{ '#Last' | dgTranslate }} {{page.totalPage}}</button>
		    </li>
		  </ul>
		</nav>
	</div>
</div>
