<div class="container-fluid mt-4">
  <div class="row">
    <div class="col-md-12 text-center">
        <a class="btn btn-block btn-light mb-4 btn-lg link-menu" routerLinkActive="active" [routerLink]="['/admin/caisse']">
          <i class="fa fa-suitcase"></i>
          {{ 'Caisse' }}
        </a>
    </div>
  </div>
</div>
