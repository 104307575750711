import { PrintBuilder } from './PrintBuilder';
import { StarWebPrintBuilder } from './StarWebPrintBuilder.js';
export class WebPrintBuilder extends PrintBuilder {
    builder: any;
    request: any;
    constructor() {
        super();
        this.builder = new StarWebPrintBuilder();
    }
    init() {
        this.request += this.builder.createInitializationElement();
        return this;
    }
    setJustification(value = 'left') {
        this.request += this.builder.createAlignmentElement({ position: value });
        return this;
    }
    setBold(value = true) {
        this.request += this.builder.createTextElement({ emphasis: value });
        return this;
    }
    setUnderline(value = true) {
        this.request += this.builder.createTextElement({ underline: value });
        return this;
    }
    setInverse(value = true) {
        this.request += this.builder.createTextElement({ invert: value });
        return this;
    }
    writeLine(value) {
        this.request += this.builder.createTextElement({ data: `${value}\n` });
        return this;
    }
    setSize(size = 'normal') {
        let fontSize;
        switch (size) {
            case 'large':
                fontSize = 2;
                break;
            default:
                fontSize = 1;
                break;
        }
        this.request += this.builder.createTextElement({ width: fontSize, height: fontSize });
        return this;
    }
    cut(type = 'full') {
        this.request += this.builder.createCutPaperElement({ feed: true, type: type });
        return this;
    }
    feed(lines = 1) {
        this.request += this.builder.createFeedElement({ line: lines });
        return this;
    }
    flush() {
        return this.request;
    }
}
