
<nav class="navbar navbar-expand-md navbar-light bg-light">
  <a class="navbar-brand" [routerLink]="['/admin/dashboard']">
    <img src="/assets/img/logo.png" width="60"  class="d-inline-block v-align " alt="">
    Pecho-CBD.com
  </a>
  <div class="collapse navbar-collapse">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" [routerLink]="['/admin/dashboard']">
          <i class="fa fa-home"></i>
          {{ 'Dashboard' }}
        </a>
      </li>

     <!--  <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" [routerLink]="['/admin/users']">
          <i class="fa fa-suitcase"></i>
          {{ 'Clients' }}
        </a>
      </li> -->

      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" [routerLink]="['/admin/caisse']">
          <i class="fa fa-eur"></i>
          {{ 'Caisse' }}
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" [routerLink]="['/admin/tickets']">
          <i class="fa fa-list"></i>
          {{ 'Tickets' }}
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" [routerLink]="['/admin/orders']">
          <i class="fa fa-list"></i>
          {{ 'Orders' }}
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" [routerLink]="['/admin/products']">
          <i class="fa fa-list"></i>
          {{ 'Produtcs' }}
        </a>
      </li>
    </ul>
  </div>
  <ul class="navbar-nav px-3">
    <li class="nav-item text-nowrap">
      <a class="nav-link" (click)="logout()"><i class="fa fa-sign-out text-danger"></i></a>
    </li>
  </ul>
</nav>

<div class="container-fluid body-content-sections">
  <router-outlet></router-outlet>
</div>
