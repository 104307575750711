import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProductsService } from '../../products.service';
import { CloudinaryWidgetManager } from 'ngx-cloudinary-upload-widget';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';

import { webConfig } from '../../../../web-config';

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss']
})
export class ProductEditComponent implements OnInit {

  isLoading: boolean = false;

  model: any = {
    type: 'simple',
  };

  tempProducts = [];

  sub: any = {};

  constructor(
    public bsModalRef: BsModalRef,
    private manager: CloudinaryWidgetManager,
    private modalService: BsModalService,
    private productsService: ProductsService,
    private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.model.prices = this.model.prices || [this._getPriceModel()];
    this.model.products = this.model.products || [this._getProductModel()];
    this.model.features = this.model.features || [this._getFeatureModel()];
    this.model.unit = this.model.unit || '';
    this.model.unit_pluriels = this.model.unit_pluriels || '';
  }

  ngOnDestroy(): void {
    this.sub.autocompleteProduct && this.sub.autocompleteProduct.unsubscribe();
  }

  // onOpen(fieldName): void {
  //   this.manager.open({ uploadPreset:'zmhvrwqr' }).subscribe((resp) => {
  //     console.log(resp);
  //     if ('success' === resp.event && resp.info.url) {
  //       this.model[fieldName] = resp.info.url;

  //       console.log('SUCCCCESSSS')
  //     }
  //   }, (err) => {
  //     console.log('err', err);
  //   }, () => {
  //     console.log('complete');
  //   });
  // }

  onOpen(fieldName): void {
  }

  onFileSelected(event: any, fieldName: string) {
    const files = (event.target as HTMLInputElement).files;
    if (files) {
      const formData = new FormData();
      formData.append('file', files[0]);

      this.isLoading = true;
      this.sub.postFiles && this.sub.postFiles.unsubscribe && this.sub.postFiles.unsubscribe();

      this.sub.postFiles = this.productsService.uploadImage(formData)
        .subscribe((res: any) => {
          this.isLoading = false;
          if (res.filename) {
            this.model[fieldName] = res.filename;
          }
        },
        (e: any) => this.isLoading = false,
        () => this.isLoading = false)
    }
  }

  getUrlFile(filename: string) {
    return this.productsService.getUrlFile(filename);
  }

  cancel() {
    this.model = {
      type: 'simple',
    };
    this.bsModalRef.hide();
    this.productsService.onListUpdated.emit(true);
  }

  onSelectedItem(item, product) {
    product.product = item;
    // this.selected = item;
    this.tempProducts = [];
  }

  onCleared(item, product) {
    // this.selected = {};
    product.product = {};
    this.tempProducts = [];
  }

  onChangeSearch(val) {
    if (val) {
      this.sub.autocompleteProduct && this.sub.autocompleteProduct.unsubscribe();

      this.sub.autocompleteProduct = this.productsService.getAutocomplete(val).subscribe((response: any) => {
        if (response && response.content) {
          this.tempProducts = response.content;
        }
      });
    }
  }

  save() {
    this.isLoading = true;
    this.productsService.put(this.model.id, this.model).subscribe((response: any) => {
      if (response.done) {
        this.toastrService.success('Done !');
        this.cancel();
      } else {
        this.toastrService.error(response.message);
      }
    }, (e) => {}, () => this.isLoading = false );
  }

  addPrice() {
    this.model.prices.push(this._getPriceModel());
  }

  removePrice(model) {
    if (this.model.prices.length) {
      _.remove(this.model.prices, {
        uid: model.uid
      });
    }
  }

  addProduct() {
    this.model.products.push(this._getProductModel());
  }

  removeProduct(model) {
    if (this.model.products.length) {
      _.remove(this.model.products, {
        uid: model.uid
      });
    }
  }

  addFeature() {
    this.model.features.push(this._getFeatureModel());
  }

  removeFeature(model) {
    if (this.model.features.length) {
      _.remove(this.model.features, {
        uid: model.uid
      });
    }
  }

  _getProductModel() {
    return  {
      id: 0,
      uid: this.getUniqueId(),
      is_active: false,
      product_id: 0,
      product: {},
      quantity: 1,
      price: 0,
    }
  }

  _getPriceModel() {
    return  {
      id: 0,
      uid: this.getUniqueId(),
      is_active: false,
      quantity: 1,
      price: 0
    }
  }

  _getFeatureModel() {
    return  {
      id: 0,
      uid: this.getUniqueId(),
      is_active: false,
      name: '',
      value: ''
    }
  }

  getUniqueId(): string {
    const parts = 5;
    const stringArr = [];
    for(let i = 0; i< parts; i++){
      // tslint:disable-next-line:no-bitwise
      const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      stringArr.push(S4);
    }
    return stringArr.join('-');
  }

}
