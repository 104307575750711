import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProductsService } from '../../products.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-product-create',
  templateUrl: './product-create.component.html',
  styleUrls: ['./product-create.component.scss']
})
export class ProductCreateComponent implements OnInit {

  isLoading: boolean = false;
  model: any = {
    type: 'simple'
  };

  constructor(
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private productsService: ProductsService,
    private toastrService: ToastrService) { }


  ngOnInit(): void {
  }

  cancel() {
    this.model = {
      type: 'simple'
    };

    this.bsModalRef.hide();
    this.productsService.onListUpdated.emit(true);
  }

  save() {
    this.isLoading = true;
    this.productsService.create(this.model).subscribe((response: any) => {
      if (response.done) {
        this.cancel();
      } else {
        this.toastrService.error(response.message);
      }
    }, (e) => {}, () => this.isLoading = false );
  }
}
