import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { OrdersService } from './orders.service';

import { webConfig } from '../../web-config';

import { OrdersModalEditComponent } from './orders-modal/orders-modal-edit/orders-modal-edit.component';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
  bsModalRef: BsModalRef;

	isLoading: boolean = false;

  subjectUsers;
  subjectInvoice;

  orders: any[] = [];

	page = {
    totalElements: 0,
    currentTotalElements: 0,
    totalPage: 0,
    pageNumber: 1,
    size: 10,
    query: '',
    status: 1
  };

  constructor(
    private ordersService: OrdersService,
    private modalService: BsModalService) { }

  ngOnInit(): void {
    this.initOrders();
  }

  ngOnDestroy(): void {
    this.subjectUsers && this.subjectUsers.unscbscribe && this.subjectUsers.unscbscribe();
  }

  initOrders(event?) {
    if (this.isLoading) {
      return;
    }

    this.subjectUsers && this.subjectUsers.unsubscribe();
    this.isLoading = true;

    this.subjectUsers = this.ordersService.getAll(this.page).subscribe((data: any) => {
      if (data.content) {
        this.orders = data.content;
        this.orders.forEach((order) => {
          order.cart_products = Object.values(order.cart_products);
        });

        this.page.totalElements = data.count;
        this.page.totalPage = Math.ceil(data.count /  this.page.size);
        this.page.currentTotalElements = data.count;
      }

      this.isLoading = false;
    });
  }

  downloadInvoice(id) {
    console.log('downloadInvoice');
    this.ordersService.getInvoice(id).subscribe((data: any) => {
      var downloadURL = URL.createObjectURL(data);
      window.open(downloadURL);
    });
  }

  openEditOrdersModal(model) {
    const initialState = {
      model: model
    };

    this.bsModalRef = this.modalService.show(OrdersModalEditComponent, {
      keyboard: false,
      initialState: initialState,
      class: 'modal-xl'
    });
  }

}
