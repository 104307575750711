import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TicketsService } from './tickets.service';

import { webConfig } from '../../web-config';
import { ProductsService } from '../products/products.service';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {
  bsModalRef: BsModalRef;

  isLoading: boolean = false;

  subjectUsers;

  tickets: any[] = [];

  page = {
    totalElements: 0,
    currentTotalElements: 0,
    totalPage: 0,
    pageNumber: 1,
    size: 20,
    query: '',
    status: 1
  };

  constructor(
    private ticketsService: TicketsService,
    private productsService: ProductsService,
    private modalService: BsModalService) { }

  ngOnInit(): void {
    this.initTickets();
  }

  initTickets(event?) {
    if (this.isLoading) {
      return;
    }

    this.subjectUsers && this.subjectUsers.unsubscribe();
    this.isLoading = true;

    this.subjectUsers = this.ticketsService.getAll(this.page).subscribe((data: any) => {
      if (data.content) {
        this.tickets = data.content;

        this.page.totalElements = data.count;
        this.page.totalPage = Math.ceil(data.count /  this.page.size);
        this.page.currentTotalElements = data.count;
      }

      this.isLoading = false;
    });
  }

  getUrlFile(filename: string) {
    return this.productsService.getUrlFile(filename);
  }
}
