<div class="container-auth">
	<div class="my-login-page">
		<section class="h-100">
			<div class="container h-100">
				<div class="row justify-content-md-center h-100">
					<div class="card-wrapper">
						<div class="brand">
							<img class="logo-img-brand" src="/assets/img/logo.png">
						</div>
						<div class="card fat">
							<div class="card-body">
								<h4 class="card-title pb-4">{{ 'Signin' }}</h4>
								<form [formGroup]="loginForm" (submit)="submitLogin()">

									<div class="form-group">
										<label for="email">{{ 'Email' }}</label>

										<input id="email" type="input" formControlName="email"  class="form-control" name="email" value="" placeholder="example@gmail.com" required autofocus>
									</div>

									<div class="form-group mt-3">
										<label for="password">
											{{ 'Password' }}
										</label>
										<input id="password" formControlName="password" placeholder="Your password" type="password" class="form-control" name="password" required data-eye>
									</div>

									<div class="form-group no-margin text-center mt-3">
										<button type="submit" [disabled]="isLoading" class="btn btn-primary col-12">
											{{ 'Signin' }}
										</button>
									</div>
								</form>
							</div>
						</div>
						<div class="footer">
							<div class="row">
								<div class="col-md-12 mt-4">
									{{ 'Copyright' }} &copy; {{ companyName }}
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</div>



