import { PrintBuilder } from './PrintBuilder';
import { PrintBuffer } from "./PrintBuffer";
const ESC = 0x1b;
const GS = 0x1D;
export class StarPrintBuilder extends PrintBuilder {
    encoder: any;
    buffer: any;

    constructor() {
        super();
        this.encoder = new TextEncoder();
    }
    init() {
        this.buffer = new PrintBuffer();
        this.write(ESC);
        this.write("@");
        return this;
    }
    flush() {
        return this.buffer.flush();
    }
    feed(lineCount = 1) {
        this.write(ESC);
        this.write("a");
        this.write(lineCount);
        return this;
    }
    cut(cutType = 'full') {
        this.write(ESC);
        this.write("d");
        this.write(cutType === 'full' ? 2 : 3);
        return this;
    }
    writeLine(value) {
        return this.write(`${value}\n`);
    }
    setInverse(inverse = true) {
        this.write(ESC);
        (inverse) ? this.write("4") : this.write("5");
        return this;
    }
    setUnderline(underline = true) {
        this.write(ESC);
        this.write("-");
        this.write(underline ? 1 : 0);
        return this;
    }
    setJustification(value) {
        let alignment;
        switch (value) {
            case "center":
                alignment = 1;
                break;
            case "right":
                alignment = 2;
                break;
            default:
                alignment = 0;
                break;
        }
        this.write(ESC);
        this.write(GS);
        this.write("a");
        this.write(alignment);
        return this;
    }
    setBold(bold = true) {
        this.write(ESC);
        (bold) ? this.write("E") : this.write("F");
        return this;
    }
    setSize(size) {
        this.write(ESC);
        this.write("i");
        this.write((size === 'normal') ? 0 : 1);
        this.write((size === 'normal') ? 0 : 1);
        return this;
    }
    write(value) {
        if (typeof value === "number") {
            this.buffer.writeUInt8(value);
        }
        else if (typeof value === "string") {
            this.buffer.write(this.encoder.encode(value));
        }
        else {
            this.buffer.write(value);
        }
        return this;
    }
}
