import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { OrdersService } from '../../orders.service';

@Component({
  selector: 'app-orders-modal-edit',
  templateUrl: './orders-modal-edit.component.html',
  styleUrls: ['./orders-modal-edit.component.scss']
})
export class OrdersModalEditComponent implements OnInit {
  isLoading: boolean = false;
  model: any = {};

  status = [
    "preparation",
    "attente_livraison",
    "livraison",
    "attente_boutique",
    "recu",
    "payed",
    "rejected",
    "returned",
    "attente_paiement",
  ];

  constructor(
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private ordersService: OrdersService
    ) { }

  ngOnInit(): void {
  }

  updateInvoice() {
    this.ordersService.updateInvoice(this.model).subscribe(data => {
      console.log(data);
      this.cancel()
    });
  }

  cancel() {
    this.model = {
      created_at: {}
    };
    this.bsModalRef.hide();
  }

}
