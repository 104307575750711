import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() list = [];

  @Input() page = {
    totalElements: 0,
    currentTotalElements: 0,
    totalPage: 0,
    pageNumber: 1,
    size: 10,
    query: '',
    status: 1
  };

  @Output() init = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  previousPage() {
    if (this.page.pageNumber > 1) {
      this.page.pageNumber--;
      this.init.next(this.page);
    }
  }

  nextPage() {
    if (this.page.pageNumber < this.page.totalPage) {
      this.page.pageNumber++;
      this.init.next(this.page);
    }
  }

  setFirstPage() {
    this.page.pageNumber = 1;
    this.init.next(this.page);
  }

  setLastPage() {
    this.page.pageNumber = this.page.totalPage;
    this.init.next(this.page);
  }

  setPositionPage(page) {
    this.page.pageNumber = page;
    this.init.next(this.page);
  }
}
