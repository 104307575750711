import { Injectable, EventEmitter } from '@angular/core';
import { HttpService } from '../../_/services/http/http.service';
import { webConfig } from '../../web-config';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  onListUpdated: EventEmitter<any> = new EventEmitter();

  constructor(private httpService: HttpService) {}

  getAll(page?) {
    page = page || {
      totalElements: 0,
      pageNumber: 0,
      size: 10,
      query: ''
    };

    return this.httpService.get(`products/?page=${page.pageNumber}&size=${page.size}&query=${page.query}`);
  }

  getAutocomplete(query) {
    let page = {
      totalElements: 0,
      pageNumber: 0,
      size: 10,
      query: query
    };

    return this.httpService.get(`products/?page=${page.pageNumber}&size=${page.size}&query=${page.query}`);
  }

  getOne(id) {
    return this.httpService.get(`products/${id}`);
  }

  create(product: any) {
    return this.httpService.post(`products`, product);
  }

  put(id, product: any) {
    return this.httpService.put(`products/${id}`, product);
  }

  remove(id) {
    return this.httpService.delete(`products/${id}`);
  }

  uploadImage(formData) {
    return this.httpService.postFiles('products/image', formData);
  }

  getUrlFile(filename: string) {
    if (!filename) {
      return '';
    }

    const cloudName = 'http://res.cloudinary.com/dzfbxltzx';
    const cloudName2 = 'https://res.cloudinary.com/dzfbxltzx';
    const isCloudinary = filename.substring(0, cloudName.length) === cloudName;
    const isCloudinary2 = filename.substring(0, cloudName2.length) === cloudName2;

    filename.split('/').pop();

    if (filename.substring(0, 4) === 'http' && !isCloudinary && !isCloudinary2) {
      return filename;
    }

    if (isCloudinary || isCloudinary2) {
      console.log('cloudinary', `${webConfig.urlCustomer}files/cloud/${filename.split('/').pop()}`)
      return `${webConfig.urlCustomer}files/cloud/${filename.split('/').pop()}`;
    }

    return `${webConfig.urlCustomer}${filename}`;
  }
}
