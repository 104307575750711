/// <reference types="web-bluetooth" />

import { Injectable, EventEmitter, Output } from '@angular/core';
import { HttpService } from '../../_/services/http/http.service';

declare const navigator: any;
declare const TextEncoder: any;

@Injectable({
  providedIn: 'root'
})
export class CaisseService {

  SERVICE = '000018f0-0000-1000-8000-00805f9b34fb';
  WRITE = '00002af1-0000-1000-8000-00805f9b34fb';

  onTIcketUpdate: EventEmitter<any> = new EventEmitter();

  server: any;
  service: any
  channel: any;
  deviceHandle: any;

  ticket: string[] = [];

  constructor(private httpService: HttpService) {}

  @Output() onPrintConnected: EventEmitter<boolean> = new EventEmitter();

  getAll(page?: any) {
    page = page || {
      totalElements: 0,
      pageNumber: 0,
      size: 10,
      query: ''
    };

    return this.httpService.get(`products/?page=${page.pageNumber}&size=${page.size}&query=${page.query}`);
  }

  createReceipt(receipt: any) {
    return this.httpService.post(`receipt`, receipt);
  }

  connectToPrint() {
    this.deviceHandle && this.deviceHandle.gatt.disconnect();

    console.log(navigator);

    navigator.bluetooth.requestDevice({ filters: [{ services: [this.SERVICE]}] }).then(device => {
        console.log(device);
        this.deviceHandle = device;
        return device.gatt.connect();
    }).then(server => {
        this.server = server;
        console.log(server);
        return server.getPrimaryService(this.SERVICE);
    }).then(service => {
        this.service = service;
        console.log(service);
        return service.getCharacteristic(this.WRITE);
    }).then(channel => {
        this.channel = channel;
        console.log(channel);
        this.onPrintConnected.emit(true);
        return true //channel.writeValue(new TextEncoder("utf-8").encode('Started'));
    }).catch(error => {
        this.onPrintConnected.emit(false);
        console.error(error)
    })
  }

  reconnect() {
    if (!this.service) {
      return;
    }

    this.service.getCharacteristic(this.WRITE).then(channel => {
        this.channel = channel;
        console.log(channel);
        this.onPrintConnected.emit(true);
        return true //channel.writeValue(new TextEncoder("utf-8").encode('Started'));
    }).catch(error => {
        this.onPrintConnected.emit(false);
        console.error(error)
    })
  }

  writeLine(data) {
    this.ticket.push('\x1D' + '\x21' + '\x00' + data);

    return this;
  }

  clean() {
    this.ticket = [];

    return this;
  }

  setJustification(direction) {
    this.ticket.push('\x1B' + '\x61' + '\x31');

    return this;
  }

  feed(nbOfLine) {
    let line = '';
    for(let i; i < nbOfLine; i++) {
      line += '\n';
    }

    this.ticket.push(line);

    return this;
  }

  flush() {
    this.channel.writeValue(new TextEncoder("utf-8").encode(this.ticket[0]));
    // this.ticket.forEach((line) => {
    //   this.channel.writeValue(new TextEncoder("utf-8").encode(line));
    // });
    // this.channel.then((channel) => {
    //   channel;
    // }).catch(error => {

    //     this.onPrintConnected.emit(false);
    //     console.error(error)
    // }).finally(() => {
    //   this.clean();
    // });


    return this;
  }

  log() {
    console.log(this.ticket);

    return this;
  }
}
