import { Component, OnInit } from '@angular/core';
import { CaisseService } from './caisse.service';
import { PrintDriver } from 'ng-thermal-print/lib/drivers/PrintDriver';
// import { PrintService, UsbDriver, WebPrintDriver } from 'ng-thermal-print';
import { PrintService } from '../../_/services/print/print.service';
import { ProductsService } from '../products/products.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import * as _ from "lodash";

import { AddProductCaisseComponent } from './add-product-caisse/add-product-caisse.component';

@Component({
  selector: 'app-caisse',
  templateUrl: './caisse.component.html',
  styleUrls: ['./caisse.component.scss']
})
export class CaisseComponent implements OnInit {
  subjecProducts: any;
  subjecTicketUpdate: any;
  subjecPrint: any;
  isLoading: boolean = false;
  products: any[] = [];
  cart: any[] = [];
  totalArticles: number = 0;

  bsModalRef: BsModalRef;

  reduction = 0;
  realTotalPrice: any = 0;
  totalPrice: any = 0;
  totalVat: any = 0;
  totalGramme = 0;
  phone = '';
  email = '';

  quantities: number[] = [].constructor(100);

  isValidation = false;
  isPrinted = false;

  status: boolean = false;
  // usbPrintDriver: UsbDriver;
  // webPrintDriver: WebPrintDriver;

  paymentType: string = 'ESPECES';

  ip: string = '';

  page = {
    totalElements: 0,
    currentTotalElements: 0,
    totalPage: 0,
    pageNumber: 1,
    size: 1000,
    query: '',
    status: 1
  };

  filteredProducts: any = {
  };

  constructor(
    private caisseService: CaisseService,
    private printService: PrintService,
    private modalService: BsModalService,
    private productsService: ProductsService) {
    // this.usbPrintDriver = new UsbDriver();
    // this.printService.isConnected.subscribe(result => {
    //     this.status = result;
    //     if (result) {
    //       console.log('Connected to printer!!!');
    //     } else {
    //       console.log('Not connected to printer.');
    //     }
    // });

    // this.requestUsb();



    // this.connectToPrint();
  }

  ngOnInit(): void {
    this.subjecPrint = this.caisseService.onPrintConnected.subscribe((result) => {
      this.status = result;
      if (result) {
        this.printService.setDriver(this.caisseService.channel);
        console.log('Connected to printer!!!');
      } else {
        console.log('Not connected to printer.');
      }
    });

    if (this.caisseService.service) {
      this.caisseService.reconnect()
    }

    this._initProducts();

    this.subjecTicketUpdate = this.caisseService.onTIcketUpdate.subscribe((result) => {
      this.addProductToCart(result.product, result.quantity, result.price);
    });

  }

  ngOnDestroy() {
    this.subjecProducts && this.subjecProducts.unsubscribe();
    this.subjecTicketUpdate && this.subjecTicketUpdate.unsubscribe();
    this.subjecPrint && this.subjecPrint.unsubscribe();
  }

  private _initProducts() {
    this.subjecProducts && this.subjecProducts.unsubscribe();
    this.isLoading = true;

    this.subjecProducts = this.caisseService.getAll(this.page).subscribe((data: any) => {
      if (data.content) {
        this.products = data.content;
        this._filterProduct();
      }

      this.isLoading = false;
    });
  }

  private _filterProduct() {
    _.each(this.products, (product) => {
      if (!this.filteredProducts[product.category]) {
        this.filteredProducts[product.category] = [];
      }

      this.filteredProducts[product.category].push(product);
    })
  }

  addToCart(product: any) {
    let p = _.find(this.cart, {
      id: product.id
    })

    if (!p) {
      product.quantity = 1;
      product.quantityUnit = 1;
      product.finalPrice = 0;
      product.manualPrice = 0;
      this.cart.push(product);
    } else {
      p.quantity = p.quantity + 1;
    }

    this.calcul();
  }

  addProductToCart(product: any, quantity: number, price: number) {
    let p = _.find(this.cart, {
      id: product.id
    })

    if (!p) {
      product.quantity = quantity;
      product.quantityUnit = 1;
      product.finalPrice = price * quantity;
      product.manualPrice = 0;
      this.cart.push(product);
    } else {
      p.quantity = quantity;
      p.finalPrice = price * quantity;
      p.quantityUnit = 1;
    }

    this.calcul();
  }

  updateProductQuantiry(product: any) {
    this.calcul();
  }

  // lessToCart(product: any) {
  //   if (product.quantity > 1) {
   //    product.quantity--;
  //   }
  //   this.calcul();
  // }

  // moreToCart(product: any) {
  //   product.quantity++;
  //   this.calcul();
  // }

  delete(product: any) {
    _.remove(this.cart, {
      id: product.id
    });

    this.calcul();
  }

  connectToPrint() {
    // this.usbPrintDriver.requestUsb().subscribe(result => {
    //     this.printService.setDriver(this.usbPrintDriver, 'ESC/POS');
    // });

    this.caisseService.connectToPrint();
  }

  connectToWebPrint() {
      // this.webPrintDriver = new WebPrintDriver(this.ip);
      // this.printService.setDriver(this.webPrintDriver, 'WebPRNT');
  }

  print() {
    let receipt = {
      reduction: this.reduction,
      realTotalPrice: this.realTotalPrice,
      totalPrice: this.totalPrice,
      totalVat: this.totalVat,
      totalGramme: this.totalGramme,
      paymentType: this.paymentType,
      phone: this.phone,
      email: this.email,
      cart: this.cart
    }

    this.isPrinted = true;
    this.caisseService.createReceipt(receipt).subscribe(async (response: any) => {
      console.log(response);
      if (response.done) {

        try {
          this._print(response.id, response.refId);
          await new Promise(resolve => setTimeout(resolve, 3000));
          this._print(response.id, response.refId, true);
        }
        catch(e) {
          alert(e);
        }

        this.isValidation = false;
        this.cart = [];
        this.calcul();
        this.isPrinted = false;
      }

    });
  }

  wait(ms){
     const start = new Date().getTime();
     let end = start;
     while(end < start + ms) {
       end = new Date().getTime();
    }
  }

  // print() {
  //   let receipt = {
  //     reduction: this.reduction,
  //     realTotalPrice: this.realTotalPrice,
  //     totalPrice: this.totalPrice,
  //     totalVat: this.totalVat,
  //     totalGramme: this.totalGramme,
  //     phone: this.phone,
  //     email: this.email,
  //     cart: this.cart
  //   }

  //   // this.isPrinted = true;
  //   // this.caisseService.createReceipt(receipt).subscribe((response: any) => {
  //     // if (response.done) {
  //       this._print(2323, '43434');
  //       // this._print(2323, '43434', true);

  //       // this.isValidation = false;
  //       // this.cart = [];
  //       // this.calcul();
  //       // this.isPrinted = false;
  //     // }

  //   // });
  // }

  // _print(id: number, refId: string, isShop?: boolean) {
  //   let d = new Date;
  //   let dformat = [d.getDate(), d.getMonth()+1, d.getFullYear()].join('/') + ' '
  //     + [d.getHours(), d.getMinutes(),  d.getSeconds()].join(':');

  //   this.caisseService.clean()
  //     .setJustification('center')
  //     .writeLine('WSW Group SAS')
  //     .writeLine('SIRET 899 787 782 00 015')
  //     .writeLine('Pecho-CBD.com')
  //     .writeLine('14 avenue Corbera')
  //     .writeLine('PARIS 12E')
  //     .writeLine(dformat)
  //     .feed(1);

  //    this.caisseService.setJustification('right');

  //   this.caisseService.writeLine('-----------------------------------------------');
  //   for(let product of this.cart) {
  //     this.caisseService.writeLine(product.quantity + (['fleur', 'hash'].includes(product.category) ? 'g' : '') + ' ' + product.name + '    ' + (product.quantity * product.finalPrice).toFixed(2) + ' Eur')
  //   }
  //   this.caisseService.writeLine('-----------------------------------------------');

  //   if (this.reduction) {
  //     this.caisseService.feed(1);
  //     this.caisseService.writeLine('Prix TTC: ' + this.realTotalPrice.toFixed(2) + ' Eur')
  //     this.caisseService.writeLine('Reduction : -' + this.reduction.toFixed(2) + ' Eur')
  //   }
  //   this.caisseService.feed(1);
  //   this.caisseService.writeLine('Total HT : ' + (this.totalPrice - this.totalVat).toFixed(2) + ' Eur')
  //     .writeLine('TVA 20 % : ' + this.totalVat.toFixed(2) + ' Eur')
  //     .writeLine('Total TTC : ' + this.totalPrice.toFixed(2) + ' Eur')
  //     .feed(1);
  //   this.caisseService.writeLine('-----------------------------------------------')
  //     .writeLine('REF: ' + refId)
  //     .writeLine('NB' + id)
  //     .writeLine('Paiment type : ' + this.paymentType)
  //     .writeLine('-----------------------------------------------')
  //     .feed(1);

  //   this.caisseService.setJustification('center');
  //   if (isShop) {
  //     this.caisseService.feed(1)
  //       .writeLine('A CONSERVER PAR LA BOUTIQUE')
  //       .feed(2);
  //   } else {
  //     this.caisseService.feed(1)
  //       .writeLine('Rendez vous sur https://pecho-cbd.com')
  //       .writeLine('MERCI ET A BIENTOT');
  //   }

  //   this.caisseService
  //     .feed(4)
  //     .feed(4)
  //     // .cut('full')
  //     .log()
  //     .flush();
  // }

  _print(id: number, refId: string, isShop?: boolean) {
    let d = new Date;
    let dformat = [d.getDate(), d.getMonth()+1, d.getFullYear()].join('/') + ' '
      + [d.getHours(), d.getMinutes(),  d.getSeconds()].join(':');

    let ticket = this.printService.init()
      .setBold(true)
      .setJustification('center')
      .writeLine('WSW Group SAS')
      .writeLine('SIRET 899 787 782 00 015')
      .writeLine('Pecho-CBD.com')
      .writeLine('Le148ConceptStore.com')
      .writeLine('70 Rue Mouffetard')
      .writeLine('PARIS 5E')
      .writeLine(dformat)
      .feed(1);

     ticket.setJustification('right');

    ticket.writeLine('-----------------------------------------------');
    for(let product of this.cart) {
      ticket.writeLine((product.quantity * product.quantityUnit) + (['fleur', 'hash'].includes(product.category) ? 'g' : '') + ' ' + product.name + '    ' + (product.finalPrice).toFixed(2) + ' Eur')
    }
    ticket.writeLine('-----------------------------------------------');

    if (this.reduction) {
      ticket.feed(1);
      ticket.writeLine('Prix TTC: ' + this.realTotalPrice + ' Eur')
      ticket.writeLine('Reduction : -' + this.reduction + ' Eur')
    }

    ticket.feed(1);
    ticket.writeLine('Total HT : ' + (this.totalPrice - this.totalVat).toFixed(2) + ' Eur')
      .writeLine('TVA 20 % : ' + this.totalVat + ' Eur')
      .writeLine('Total TTC : ' + this.totalPrice + ' Eur')
      .feed(1);

    ticket.writeLine('-----------------------------------------------')
      .writeLine('REF: ' + refId)
      .writeLine('NB' + id)
      .writeLine('Paiment type : ' + this.paymentType)
      .writeLine('-----------------------------------------------')
      .feed(1);

    ticket.setJustification('center');
    if (isShop) {
      ticket.feed(1)
        .writeLine('A CONSERVER PAR LA BOUTIQUE')
        .feed(2);
    } else {
      ticket.feed(1)
        .writeLine('Rendez vous sur https://pecho-cbd.com')
        .writeLine('MERCI ET A BIENTOT');
    }

    ticket
      .feed(4)
      .setBold(false)
      .feed(8)
      .cut('full')
      .flush();
  }


  calcul() {
    this.totalPrice = 0;
    this.realTotalPrice = 0;
    this.totalVat = 0;
    this.totalGramme = 0;
    this.reduction = 0;
    this.totalArticles = 0;

    for(let product of this.cart) {
      this.totalArticles += +(product.quantityUnit) * product.quantity;
      if (product.category == 'fleur' || product.category == 'hash') {
        this.totalGramme += product.quantity;
      }
      let realTotalPrice = product.finalPrice;
      let totalPrice = 0;
      let manualPrice = parseInt('' + product.manualPrice);
      if (manualPrice != -1) {
        totalPrice = manualPrice ? manualPrice : product.quantity * product.finalPrice;
      }

      // if (totalPrice > realTotalPrice) {
      //   totalPrice = realTotalPrice;
      //   product.manualPrice = realTotalPrice;
      // }

      product.finalPrice = realTotalPrice;
      this.totalPrice += totalPrice * product.quantityUnit;
      this.realTotalPrice += product.finalPrice * product.quantityUnit;

    }

    this.totalPrice = '' + this.totalPrice.toFixed(2);
    this.realTotalPrice = '' + this.realTotalPrice.toFixed(2);

    if (this.realTotalPrice !== this.totalPrice) {
      this.reduction = this.realTotalPrice - this.totalPrice;
    }

    this.totalVat = this.totalPrice / 100 * 20;
    this.totalVat = '' + this.totalVat.toFixed(2);
  }

  openAddProductModal() {
    const initialState: any = {
      filteredProducts: this.filteredProducts,
      products: this.products
    };

    this.bsModalRef = this.modalService.show(AddProductCaisseComponent, {
      keyboard: false,
      initialState: initialState,
      class: 'modal-xl'
    });
  }

  getUrlFile(filename: string) {
    return this.productsService.getUrlFile(filename);
  }

}
