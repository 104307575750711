<div class="row">
	<div class="col-12">
		<button class="btn btn-primary float-end" (click)="openCreateProductModal()">Add product</button>
		<h1>{{ page.totalElements }} products</h1>
	</div>
</div>
<div class="row">

	<div class="col-12">
		<div class="container-fluid mt-4 mb-4" *ngIf="!isLoading && page.size < page.currentTotalElements">
			<app-pagination [page]="page" [list]="products" (init)="initProducts($event)"></app-pagination>
		</div>
	</div>

	<div class="col-12" *ngIf="!isLoading">
		<div class="row">
			<div class="col-md-4" *ngFor="let product of products">
				<div class="btn card mb-3 product-selection" >
					<div class="card-body text-center" (click)="openEditProductModal(product)">
						<b class="mb-2">{{ product.name }}</b> <br />
						<b class="badge badge-primary">{{ product.min_price }} €</b> <br />
						<img class="img-fluid product-img" [src]="getUrlFile(product.image_one)">
					</div>
					<div class="card-footer text-center">
						<button class="btn btn-danger" (click)="removeProduct(product.id)">Supprimer</button>
					</div>
				</div>

			</div>
		</div>
	</div>

	<div class="col-12">
		<div class="container-fluid mt-4 mb-4" *ngIf="!isLoading && page.size < page.currentTotalElements">
			<app-pagination [page]="page" [list]="products" (init)="initProducts($event)"></app-pagination>
		</div>
	</div>
</div>
<div *ngIf="isLoading"  class="spinner-ctnr">
	<div class="logo-fixed"></div>
	<div class="spinner colorwheel">
	<div class="centerpiece"></div>
</div>
