import { Injectable, EventEmitter } from '@angular/core';
import { HttpService } from '../../_/services/http/http.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  onListUpdated: EventEmitter<any> = new EventEmitter();
  onUserUpdated: EventEmitter<any> = new EventEmitter();
  onWorkoutSaved: EventEmitter<any> = new EventEmitter();

  constructor(private httpService: HttpService) {}

  getAll(page?) {
    page = page || {
      totalElements: 0,
      pageNumber: 0,
      size: 10,
      query: ''
    };

    return this.httpService.get(`user/?page=${page.pageNumber}&size=${page.size}&query=${page.query}`);
  }

  getAllRoles() {
    let page = {
      pageNumber: 0,
      size: 100,
    };

    return this.httpService.get(`role/?page=${page.pageNumber}&size=${page.size}`);
  }

  getOne(id) {
    return this.httpService.get(`user/${id}`);
  }

  createUser(model) {
    return this.httpService.post(`user`, model);
  }

  updateUser(id, model) {
    return this.httpService.put(`user/${id}`, model);
  }

  removeUser(id) {
    return this.httpService.delete(`user/${id}`);
  }

  connectAs(id) {
    return this.httpService.get(`connect/as/${id}`);
  }
}
