import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UsersService } from '../../users.service';
import { ToastrService } from 'ngx-toastr';

import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

import { webConfig } from '../../../../web-config';

import { FormModalCore } from '../../../../_/core/form-modal.core';

@Component({
  selector: 'tpc-users-modal-create',
  templateUrl: './users-modal-create.component.html',
  styleUrls: ['./users-modal-create.component.scss']
})
export class UsersModalCreateComponent extends FormModalCore implements OnInit {

  birthdayModel: NgbDateStruct = {
    day: 1,
    month: 1,
    year: 2002
  };

	model: any = {
    data: {},
    role_id: 0,
    timezone: '+00:00',
    gender: 1,
    body_units: 1,
  };

  subjectRoles;
  roles: any[] = [];

  errors: any = {}
  errorsMessage: string = '';
  isLoading: boolean = false;

  constructor(
    private calendar: NgbCalendar,
  	public bsModalRef: BsModalRef,
  	private usersService: UsersService,
  	private toastrService: ToastrService) {
    super();
  }

  ngOnInit(): void {
  }

  cancel() {
		this.model = {
      data: {},
      role_id: 0,
      timezone: '+00:00',
      gender: 1,
      body_units: 1,
    };

    this.bsModalRef.hide();
  }

  save() {
    this.startLoading();

  	this.usersService.createUser(this.model).subscribe((data: any) => {
      if (!data.errors) {
        this.cancel();
        this.usersService.onListUpdated.emit(true);
        this.toastrService.success('User created !');
      } else {
      	this.errors = data.errors;
        this.toastrService.error(data.message || 'An error has occurred');
      }
  	}, error => this.toastrService.error('An error has occurred'));
  }
}
