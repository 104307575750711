import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProductsService } from './products.service';

import { ProductCreateComponent } from './products-modal/product-create/product-create.component';
import { ProductEditComponent } from './products-modal/product-edit/product-edit.component';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  bsModalRef: BsModalRef;

  isLoading: boolean = false;

  subjectUsers;
  subjectProducts;
  subjectProductRemove;

  products: any[] = [];

  page = {
    totalElements: 0,
    currentTotalElements: 0,
    totalPage: 0,
    pageNumber: 1,
    size: 100,
    query: '',
    status: 1
  };

  constructor(
    private productsService: ProductsService,
    private modalService: BsModalService) { }

  ngOnInit(): void {
    this.initProducts();

    this.subjectProducts = this.productsService.onListUpdated.subscribe(() => this.initProducts());
  }

  ngOnDestroy(): void {
    this.subjectUsers && this.subjectUsers.unsubscribe();
    this.subjectProducts && this.subjectProducts.unsubscribe();
    this.subjectProductRemove && this.subjectProductRemove.unsubscribe();
  }

  initProducts(event?) {
    if (this.isLoading) {
      return;
    }

    this.subjectUsers && this.subjectUsers.unsubscribe();
    this.isLoading = true;

    this.subjectUsers = this.productsService.getAll(this.page).subscribe((data: any) => {
      if (data.content) {
        this.products = data.content;

        this.page.totalElements = data.count;
        this.page.totalPage = Math.ceil(data.count /  this.page.size);
        this.page.currentTotalElements = data.count;
      }

      this.isLoading = false;
    });
  }

  removeProduct(id) {
    if (this.isLoading) {
      return;
    }

    this.subjectProductRemove && this.subjectProductRemove.unsubscribe();
    this.isLoading = true;

    this.subjectProductRemove = this.productsService.remove(id).subscribe((data: any) => {
      this.isLoading = false;
      this.initProducts();
    });
  }

  openCreateProductModal() {
    const initialState = {
    };

    this.bsModalRef = this.modalService.show(ProductCreateComponent, {
      keyboard: false,
      initialState: initialState,
      class: 'modal-xl'
    });
  }

  openEditProductModal(model) {
    const initialState = {
      model: model
    };

    this.bsModalRef = this.modalService.show(ProductEditComponent, {
      keyboard: false,
      initialState: initialState,
      class: 'modal-xl'
    });
  }

  getUrlFile(filename: string) {
    return this.productsService.getUrlFile(filename);
  }

}
