<h1>{{ page.totalElements }} tickets</h1>


<div class="row">

	<div class="col-12">
		<div class="container-fluid mt-4 mb-4" *ngIf="!isLoading && page.size < page.currentTotalElements">
			<app-pagination [page]="page" [list]="tickets" (init)="initTickets($event)"></app-pagination>
		</div>
	</div>

	<div class="col-12" *ngIf="!isLoading">
		<div class="card mb-3" *ngFor="let ticket of tickets">
			<div class="card-header">
				<div class="row">
					<div class="col-6">
						<h3>
							<i class="fa fa-calendar"></i> {{ ticket.created_at.date | date: 'd MMMM, h:mm a' }}
						</h3>
						<small>Order Id: {{ ticket.ref_id }}</small>
						<small>{{ ticket.payment_type ? ' [' + ticket.payment_type + ']' : '' }}</small>
					</div>
					<div class="col-6 text-end">
						<span class="badge bg-success me-2 text-light fs-2">{{ ticket.total_price }}€ <del *ngIf="ticket.total_price !== ticket.real_total_price">{{ ticket.real_total_price }}€</del></span>
						<span class="badge bg-light me-2 text-dark">{{ ticket.data.cart.length }} products</span>
						<button class="btn btn-secondary"><i class="fa fa-eye"></i> Details</button>
					</div>
				</div>
			</div>
			<div class="card-body">
        <div *ngFor="let product of ticket.data.cart" class="card mt-3">
					<div class="card-body">
						<div class="row">
							<div class="col-2"><img class="img-fluid" width="50px" [src]="getUrlFile(product.image_one)" /></div>
							<div class="col-5">
								<b>{{ product.name }}</b> <br />
							</div>
							<div class="col-2" *ngIf="product.quantityUnit">
								{{ product.quantity * product.quantityUnit }} {{ product.quantity * product.quantityUnit > 1 ? product.unit_pluriels : product.unit }}
							</div>
							<div class="col-2" *ngIf="!product.quantityUnit">
								{{ product.quantity }} {{ product.quantity > 1 ? product.unit_pluriels : product.unit }}
							</div>
							<div class="col-2 text-right"><span class="text-nowrap">{{ (product.finalPrice) }} €</span></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="col-12">
		<div class="container-fluid mt-4 mb-4" *ngIf="!isLoading && page.size < page.currentTotalElements">
			<app-pagination [page]="page" [list]="tickets" (init)="initTickets($event)"></app-pagination>
		</div>
	</div>
</div>
<div *ngIf="isLoading"  class="spinner-ctnr">
	<div class="logo-fixed"></div>
	<div class="spinner colorwheel">
	<div class="centerpiece"></div>
</div>
