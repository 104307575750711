import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { DashboardService } from './dashboard.service';

import { UsersModalCreateComponent } from '../users/users-modal/users-modal-create/users-modal-create.component';

@Component({
  selector: 'tpc-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  bsModalRef: BsModalRef;

  public pieChartLabels:string[] = [];
  public pieChartData:number[] = [];
  public pieChartType:string = 'pie';
  public pieChartColors:any = [
      {
        backgroundColor: []
      }
  ];

  subjects: any = {};

  constructor(
    private dashboardService: DashboardService,
    private modalService: BsModalService) { }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
  }

  openCreateUserModal() {
    const initialState = {};
    this.bsModalRef = this.modalService.show(UsersModalCreateComponent, {
      initialState: initialState,
      class: 'modal-xs'
    });
  }
}
