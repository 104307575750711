<div *ngIf="!isLoading && !model.data" class="text-center">
	<p class="alert alert-danger">
		{{ 'User not found!' }}
	</p>
	<a href="#" class="btn btn-light" [routerLink]="['/admin', 'users']">{{ 'Back' }}</a>
</div>
<div *ngIf="model.data && model.data.firstname">
	<h2 class="title-rubrique">
		<i class="fa fa-user"></i> {{ model.data && model.data.firstname }} {{ model.data && model.data.lastname }}
		<small *ngIf="model.role && model.role.name">({{ model.role.name }}) <span *ngIf="model.data" class="small-title">{{ model.data.sport }}</span></small>
		<button class="btn btn-primary ml-4 float-right" (click)="openEditUserModal()"><i class="fa fa-pencil"></i></button>
		<br />

	</h2>
</div>

<div *ngIf="isLoading"  class="spinner-ctnr">
  <div class="logo-fixed"></div>
  <div class="spinner colorwheel">
  <div class="centerpiece"></div>
</div>
