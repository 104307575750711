import { environment } from '../environments/environment';

export const webConfig = {
 	base: environment.base,
 	baseApi: environment.baseApi,
 	cdnPath: environment.cdnPath,
 	prefixApp: 'pecho_admin_',
 	prefixCustomer: 'pecho_customer_',
 	urlCustomer: environment.urlCustomer,
 	companyName: 'Pecho CBD | 2021',
};
