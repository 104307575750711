import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './auth.service';

import { AuthComponent } from './auth/auth.component';
import { LoginComponent } from './auth/login/login.component';

import { AdminComponent } from './admin/admin.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { UsersComponent } from './admin/users/users.component';
import { OrdersComponent } from './admin/orders/orders.component';
import { CaisseComponent } from './admin/caisse/caisse.component';
import { UsersDetailComponent } from './admin/users/users-detail/users-detail.component';
import { ProductsComponent } from './admin/products/products.component';
import { TicketsComponent } from './admin/tickets/tickets.component';

const routes: Routes = [{
    path: '',
    component: AuthComponent,
    children: [{
    	path: '',
    	component: LoginComponent
	},]
}, {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [{
        path: 'dashboard',
        component: DashboardComponent
    },{
        path: 'users',
        component: UsersComponent
    }, {
        path: 'users/:id',
        component: UsersDetailComponent
    }, {
        path: 'caisse',
        component: CaisseComponent
    }, {
        path: 'orders',
        component: OrdersComponent
    }, {
        path: 'tickets',
        component: TicketsComponent
    }, {
        path: 'products',
        component: ProductsComponent
    }]
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
