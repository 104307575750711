<div class="modal-header">
  <h4 class="modal-title pull-left"><i class="fa fa-plus"></i> {{ 'Create a new user' }}</h4>
  <button type="button" class="close pull-right" aria-label="Close" [disabled]="isLoading" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
	<div class="row" *ngIf="!isLoading">

		<div class="col-md-12">
			<div class="form-group mb-2">
				<label>{{ 'Email' }}</label>
				<input placeholder="example@gmail.com" [class.is-invalid]="errors.email" (input)="errors.email=false" [(ngModel)]="model.email" type="email" autocomplete="new-password" class="form-control">
			</div>
		</div>

		<div class="col-md-12">
			<div class="form-group mb-2">
				<label>{{ 'Password' }}</label>
				<input placeholder="Your password" [class.is-invalid]="errors.password" (input)="errors.password=false" [(ngModel)]="model.password" type="password" autocomplete="new-password" class="form-control">
			</div>
		</div>

		<div class="col-md-6">
			<div class="form-group mb-2">
				<label>{{ 'Lastname' }}</label>
				<input placeholder="Smith" [class.is-invalid]="errors.lastname" (input)="errors.lastname=false" [(ngModel)]="model.lastname" type="text" autocomplete="new-password" class="form-control">
			</div>
		</div>
		<div class="col-md-6">
			<div class="form-group mb-2">
				<label>{{ 'Firstname' }}</label>
				<input placeholder="John" [class.is-invalid]="errors.firstname" (input)="errors.firstname=false" [(ngModel)]="model.firstname" type="text" autocomplete="new-password" class="form-control">
			</div>
		</div>
	</div>

	<div *ngIf="isLoading"  class="spinner-ctnr">
	  <div class="logo-fixed"></div>
	  <div class="spinner colorwheel">
	  <div class="centerpiece"></div>
	</div>

</div>

<div class="modal-footer">
  <button class="btn btn-light mr-4" [disabled]="isLoading" (click)="cancel()">
		<i class="fa fa-close"></i> {{ 'Cancel' }}
	</button>
	<button class="btn btn-primary" [disabled]="isLoading" (click)="save()">
		<i class="fa fa-save"></i>
		{{ 'Save' }}
	</button>
</div>

