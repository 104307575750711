import { StarPrintBuilder } from '../../builders/StarPrintBuilder';
import { WebPrintBuilder } from '../../builders/WebPrintBuilder';
import { PrintBuilder } from '../../builders/PrintBuilder';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EscBuilder } from '../../builders/EscBuilder';
import * as i0 from "@angular/core";


@Injectable({
  providedIn: 'root'
})
export class PrintService extends PrintBuilder {

    isConnected: BehaviorSubject<boolean>;
    driver;
    builder;
    printLanguage: string;

    constructor() {
        super();
        this.isConnected = new BehaviorSubject(false);
    }
    /**
     *
     * @param driver UsbDriver | WebPrintDriver
     * @param printLanguage ESC/POS | StarPRNT | WebPRNT
     */
    setDriver(driver, printLanguage = 'ESC/POS') {
        this.driver = driver;
        this.printLanguage = printLanguage;
        // this.driver.connect();
        // this.driver.isConnected.subscribe(result => {
        //     this.isConnected.next(result);
        // });
        return this;
    }
    /**
     * Initialize a new print queue
     */
    init() {
        if (!this.driver) {
            throw "Cannot initiate the print service.  No connection detected.";
        }
        switch (this.printLanguage) {
            case 'WebPRNT':
                this.builder = new WebPrintBuilder();
                break;
            case 'StarPRNT':
                this.builder = new StarPrintBuilder();
                break;
            default:
                this.builder = new EscBuilder();
                break;
        }
        this.builder.init();
        return this;
    }
    /**
     *
     * @param cutType full|partial
     */
    cut(cutType = 'full') {
        this.builder.cut(cutType);
        return this;
    }
    /**
     *
     * @param lineCount How many lines to feed
     */
    feed(lineCount = 1) {
        this.builder.feed(lineCount);
        return this;
    }
    setInverse(value = true) {
        this.builder.setInverse(value);
        return this;
    }
    setBold(value = true) {
        this.builder.setBold(value);
        return this;
    }
    setUnderline(value = true) {
        this.builder.setUnderline(value);
        return this;
    }
    /**
     *
     * @param value left|center\right
     */
    setJustification(value = 'left') {
        this.builder.setJustification(value);
        return this;
    }
    /**
     *
     * @param value normal|large
     */
    setSize(value = 'normal') {
        this.builder.setSize(value);
        return this;
    }
    /**
     *
     * @param text
     */
    writeLine(text = '') {
        console.log(text);
        this.builder.writeLine(text);
        return this;
    }
    /**
     * write the current builder value to the driver and clear out the builder
     */
    flush() {
        const data = this.builder.flush();
        const max = data.length;
        const groupBy = 512;

        const page = max / groupBy;


        let j = 0
        for(let i = 0; i <= page; i++) {

          let limit = groupBy * i + groupBy;
          let s = data.slice(groupBy * i, limit);
          console.log('s', s);
          setTimeout(() => {
            this.driver.writeValueWithResponse(s)
              .then((val) => console.log(val))
              .catch((e) => console.log(e))
          }, 750 * i)

          // if (page == i) {
          //     console.log('--------- CUT ----------')

          //     let ticket = this.init()
          //         .feed(8)
          //         .cut('full')
          //         .flush()
          // }
        }

        // console.log(data.length);

        // this.driver.writeValueWithResponse(this.builder.flush());
      // console.log(this.driver, this.builder.flush());
    }
}
