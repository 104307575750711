import { Component, OnInit } from '@angular/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UsersService } from './users.service';

import { webConfig } from '../../web-config';

import { UsersModalCreateComponent } from './users-modal/users-modal-create/users-modal-create.component';

@Component({
  selector: 'tpc-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  bsModalRef: BsModalRef;

  query: string = '';

	mode: string = 'index';

	model: any = {
    sport_id: 1
  };

	selected = [];
	ColumnMode = ColumnMode;
	SelectionType = SelectionType;

	page = {
		totalElements: 0,
    currentTotalElements: 0,
    totalPage: 0,
    pageNumber: 1,
    size: 10,
    query: '',
    status: 1
	};

	users: any = [];

	columns = [
		{ prop: 'id', name: 'id' },
		{ prop: 'username', name: 'username' },
		{ prop: 'registered_at', name: 'registered_at' },
    { prop: 'status', name: 'status' },
    { prop: 'role', name: 'role' },
		{ name: 'action' }
	];

  subjectUpdateUsers;
  isLoading: boolean = false;

  subjectUsers;
  subjectRoles;

  sub: any = {};

  roles: any[] = [];

  cdnPath: string = webConfig.cdnPath;
  defaultAvatar: string = '1-avatar.png';

  constructor(
    private usersService: UsersService,
    private modalService: BsModalService) { }

  ngOnInit(): void {
  	this._initUsers();

    this.subjectUpdateUsers = this.usersService.onListUpdated.subscribe(() => {
      this._initUsers();
    });
  }

  ngOnDestroy() {
    this.subjectUsers && this.subjectUsers.unsubscribe();
    this.subjectRoles && this.subjectRoles.unsubscribe();
    this.subjectUpdateUsers && this.subjectUpdateUsers.unsubscribe();
    this.sub.subjectConnectAs && this.sub.subjectConnectAs.unsubscribe();
    this.sub.subjectRemoveUser && this.sub.subjectRemoveUser.unsubscribe();
  }

  previousPage() {
    if (this.page.pageNumber > 1) {
      this.page.pageNumber--;
      this._initUsers();
    }
  }

  nextPage() {
    if (this.page.pageNumber < this.page.totalPage) {
      this.page.pageNumber++;
      this._initUsers();
    }
  }

  setFirstPage() {
    this.page.pageNumber = 1;
    this._initUsers();
  }

  setLastPage() {
    this.page.pageNumber = this.page.totalPage;
    this._initUsers();
  }

  setPositionPage(page) {
    this.page.pageNumber = page;
    this._initUsers();
  }

  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);
  }

  onActivate(event) {
    console.log('Activate Event', event.row);
  }

  connectAs(row) {
    this.sub.subjectConnectAs && this.sub.subjectConnectAs.unsubscribe();

    this.sub.subjectConnectAs = this.usersService.connectAs(row.id).subscribe((response: any) => {
      console.log(response);
      window.open(webConfig.urlCustomer + '/connect-as/' + response.token, "_blank");
    });
  }

  removeUser(row) {
    if(confirm("Are you sure to delete " + row.username)) {
      this.sub.subjectRemoveUser && this.sub.subjectRemoveUser.unsubscribe();

      this.sub.subjectRemoveUser = this.usersService.removeUser(row.id).subscribe((response: any) => {
        this._initUsers();
      });
    }
  }

  openCreateUserModal() {
    const initialState = {};
    this.bsModalRef = this.modalService.show(UsersModalCreateComponent, {
      keyboard: false,
      initialState: initialState
    });
  }

  private _initUsers() {
    this.subjectUsers && this.subjectUsers.unsubscribe();
    this.isLoading = true;
    this.users = [];

    this.subjectUsers = this.usersService.getAll(this.page).subscribe((data: any) => {
      if (data.content) {
        this.users = data.content;
        this.page.totalElements = data.count;
        this.page.totalPage = Math.ceil(data.count /  this.page.size);
        this.page.currentTotalElements = data.count;
      }
      this.isLoading = false;
    });
  }

  private _initRoles() {
    this.subjectRoles && this.subjectRoles.unsubscribe();
    this.isLoading = true;

    this.subjectRoles = this.usersService.getAllRoles().subscribe((roles: any) => {
      if (roles.content) {
        this.roles = roles.content;
      }
      this.isLoading = false;
    });
  }

  search() {
    this.page.query = this.query;
    this._initUsers();
  }

  clear() {
    let doAction = this.query === this.page.query;
    this.query = '';
    this.page.query = '';

    if (doAction) {
      this._initUsers();
    }
  }
}
