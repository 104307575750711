import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CaisseService } from '../caisse.service';
import { ProductsService } from '../../products/products.service';

import * as _ from 'lodash';

@Component({
  selector: 'app-add-product-caisse',
  templateUrl: './add-product-caisse.component.html',
  styleUrls: ['./add-product-caisse.component.scss']
})
export class AddProductCaisseComponent implements OnInit {

  isLoading: boolean = false;
  filteredProducts: any = {};
  products: any = [];
  original: any = [];

  currentCategory: string = '';

  keyword: string = '';

  constructor(
    private caisseService: CaisseService,
    private productsService: ProductsService,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private toastrService: ToastrService) { }


  ngOnInit(): void {
  }

  ngOnDestory() {
    this.reset();
  }

  addProductToCart(product: any, quantity: number, price: number) {
    this.caisseService.onTIcketUpdate.emit({
      product,
      quantity,
      price
    })

    this.cancel();
  }

  selectCategory(category: string) {
    this.currentCategory = category;
    this.original =  _.cloneDeep(this.filteredProducts);
    // this.original = _.cloneDeep(this.filteredProducts[category]);
  }

  clearCategory() {
    this.currentCategory = '';
    this.reset();
  }

  cancel() {
    this.reset();

    this.filteredProducts = {};
    this.products = [];
    this.bsModalRef.hide();
  }

  save() {
    this.isLoading = false;
  }

  search() {
    console.log(this.keyword);
    this.filteredProducts[this.currentCategory] =  _.cloneDeep(this.original[this.currentCategory]).filter((product) => {
      return product.name.toLowerCase().includes(this.keyword.toLowerCase());
    });
  }

  reset() {
    this.keyword = '';
    this.filteredProducts[this.currentCategory] =  _.cloneDeep(this.original[this.currentCategory]);
  }

  getUrlFile(filename: string) {
    return this.productsService.getUrlFile(filename);
  }
}
