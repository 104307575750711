import { Component } from '@angular/core';
import { PrintService, UsbDriver, WebPrintDriver } from 'ng-thermal-print';
import { PrintDriver } from 'ng-thermal-print/lib/drivers/PrintDriver';
import { DoorgetsTranslateService } from 'doorgets-ng-translate';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  status: boolean = false;
	usbPrintDriver: UsbDriver;
	webPrintDriver: WebPrintDriver;
	ip: string = '127.0.0.1';

	constructor(private printService: PrintService, private doorgetsTranslateService: DoorgetsTranslateService) {
        this.usbPrintDriver = new UsbDriver();
        this.webPrintDriver = new WebPrintDriver(this.ip);

        this.printService.isConnected.subscribe(result => {
            this.status = result;
            if (result) {
                console.log('Connected to printer!!!');
            } else {
            	console.log('Not connected to printer.');
            }
        });

        this.doorgetsTranslateService.init({
          languages: ['en', 'fr'],
          current: 'fr',
          default: 'fr'
        });
        }

    requestUsb() {
        this.usbPrintDriver.requestUsb().subscribe(result => {
            this.printService.setDriver(this.usbPrintDriver, 'ESC/POS');
        });
    }

    connectToWebPrint() {
        this.webPrintDriver = new WebPrintDriver(this.ip);
        this.printService.setDriver(this.webPrintDriver, 'WebPRNT');
    }

    print() {
        this.printService.init()
            .setBold(true)
            .setJustification('center')
            .writeLine('WSW GROUP SAS')
            .writeLine('Pecho-CBD.com')
            .writeLine('14 av. Corbera')
            .writeLine('PARIS 12E')
            .writeLine('SIRET: 878987656787678')
            .feed(2)
            .setJustification('center')
            .writeLine('Name produt              30.00 Eur')
            .writeLine('Name produt              30.00 Eur')
            .writeLine('Name produt              30.00 Eur')
            .feed(2)
            .setJustification('right')
            .writeLine('Tot. HT : 48.00 Eur')
            .writeLine('TVA 20% : 12.00 Eur')
            .feed(1)
            .writeLine('Tot. TTC : 60.00 Eur')
            .feed(2)
            .setJustification('center')
            .writeLine('Rendez vous sur https://pecho-cbd.com')
            .writeLine('MERCI ET A BIENTOT')
            .feed(4)
            .setBold(false)
            .feed(4)
            .cut('full')
            .flush();
    }
}
