<div class="modal-header">
  <h4 class="modal-title float-start">
  	Edit product: {{ model.id }}
  </h4>
  <button type="button" class="close float-end" aria-label="Close" [disabled]="isLoading" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<div class="modal-body">
	<div class="row">
		<div class="col-md-3">
      <label class="mt-4 align-middle me-2">{{ "#Active" | dgTranslate }}</label>
      <label class="switch mt-4 align-middle">
        <input type="checkbox" [(ngModel)]="model.is_active" />
        <span class="slider round"></span>
      </label>
    </div>

    <div class="col-md-3">
      <label class="mt-4 align-middle me-2">{{ "#New" | dgTranslate }}</label>
      <label class="switch mt-4 align-middle">
        <input type="checkbox" [(ngModel)]="model.is_new" />
        <span class="slider round"></span>
      </label>
    </div>

    <div class="col-md-3">
      <label class="mt-4 align-middle me-2">{{ "#Featured" | dgTranslate }}</label>
      <label class="switch mt-4 align-middle">
        <input type="checkbox" [(ngModel)]="model.is_featured" />
        <span class="slider round"></span>
      </label>
    </div>

		<div class="col-12 mt-2 mb-2">
			<div class="text-center w-50 m-auto">
				<img [src]="getUrlFile(model.image_one)" class="img-fluid img-product">
			</div>
			<div class="row">
				<div class="col-md-6 mb-2">
					<div class="form-group">
						<label>Type</label>
						<select disabled class="form-control" name="type" [(ngModel)]="model.type">
							<option value="simple">Simple</option>
							<option value="grouped">Groupé</option>
							<option value="configurable">Configurable</option>
							<option value="virtuel">Virtuel</option>
						</select>
					</div>
				</div>
				<div class="col-md-6 mb-2">
					<div class="form-group">
						<label>Category</label>
						<select class="form-control" name="type" [(ngModel)]="model.category">
							<option value="fleur">Fleurs</option>
							<option value="hash">Hash/Résines</option>
							<option value="chips">Chips</option>
							<option value="eliquide">E-Liquides/Wpuff</option>
							<option value="huile">Huiles</option>
							<option value="confiserie">Confiserie</option>
							<option value="puff">Puff</option>
							<option value="accessoires">Accessoires</option>
							<option value="dabpen">Dab Pen</option>
							<option value="dabpod">Dab Pod</option>
							<option value="boisson">Boisson</option>
							<option value="chocolat">Bar Chocolat</option>
							<option value="gateau">Gateau</option>
						</select>
					</div>
				</div>
		</div>

		<div class="col-12 mb-2">
			<div class="form-group">
				<label>Name</label>
				<input type="text" class="form-control" [(ngModel)]="model.name">
			</div>
		</div>

		<div class="col-12 mb-2">
			<div class="form-group">
				<label>Slug</label>
				<input type="text" class="form-control" [(ngModel)]="model.slug">
			</div>
		</div>

		<div class="col-12 mb-2">
			<div class="form-group">
				<label>Description</label>
				<textarea class="form-control editor" [(ngModel)]="model.description"></textarea>
			</div>
		</div>

		<div class="col-12 mb-2">
			<div class="form-group">
				<label>Image 1</label>
				<input type="text" class="form-control" [(ngModel)]="model.image_one">
				<input type="file" class="file-input" (change)="onFileSelected($event, 'image_one')" #fileUploadOne accept="image/jpg, image/jpeg, image/png">
				<button class="btn btn-light ml-2" (click)="fileUploadOne.click()">Ajouter un fichier</button>
			</div>
		</div>

		<div class="col-3 mb-2">
			<img [src]="getUrlFile(model.image_one)" class="img-fluid img-product">
		</div>

		<div class="col-12 mb-2">
			<div class="form-group">
				<label>Image 2</label>
				<input type="text" class="form-control" [(ngModel)]="model.image_two">
				<input type="file" class="file-input" (change)="onFileSelected($event, 'image_two')" #fileUploadTwo accept="image/jpg, image/jpeg, image/png">
				<button class="btn btn-light ml-2" (click)="fileUploadTwo.click()">Ajouter un fichier</button>
			</div>
		</div>

		<div class="col-3 mb-2">
			<img [src]="getUrlFile(model.image_two)" class="img-fluid img-product">
		</div>

		<div class="col-12 mb-2">
			<div class="form-group">
				<label>Image 3</label>
				<input type="text" class="form-control" [(ngModel)]="model.image_three">
				<input type="file" class="file-input" (change)="onFileSelected($event, 'image_three')" #fileUploadThree accept="image/jpg, image/jpeg, image/png">
				<button class="btn btn-light ml-2" (click)="fileUploadThree.click()">Ajouter un fichier</button>
			</div>
		</div>

		<div class="col-3 mb-2">
			<img [src]="getUrlFile(model.image_three)" class="img-fluid img-product">
		</div>

		<div class="col-12">
			<div class="row">
				<div class="col-6 mb-2">
					<div class="form-group">
						<label>THC %</label>
						<input type="number" step="0.01" class="form-control" [(ngModel)]="model.thc_percent">
					</div>
				</div>
				<div class="col-6 mb-2">
					<div class="form-group">
						<label>CBD %</label>
						<input type="number" step="0.01" class="form-control" [(ngModel)]="model.cbd_percent">
					</div>
				</div>
			</div>
		</div>

		<div class="col-12">
			<div class="row">
				<div class="col-3 mb-2">
					<div class="form-group">
						<label>Prix d'achat €</label>
						<input type="number" step="0.01" class="form-control" [(ngModel)]="model.buying_price">
					</div>
				</div>

				<div class="col-3 mb-2">
					<div class="form-group">
						<label>Prix min €</label>
						<input type="number" step="0.01" class="form-control" [(ngModel)]="model.min_price">
					</div>
				</div>

				<div class="col-3 mb-2">
					<div class="form-group">
						<label>VAT %</label>
						<input type="number" step="0.01" class="form-control" [(ngModel)]="model.vat_percent">
					</div>
				</div>
			</div>
		</div>

		<div class="col-12">
			<div class="row">
				<div class="col-6 mb-2">
					<div class="form-group">
						<label>Unité</label>
						<input type="text" class="form-control" [(ngModel)]="model.unit">
					</div>
				</div>
				<div class="col-6 mb-2">
					<div class="form-group">
						<label>Unité pluriels</label>
						<input type="text" class="form-control" [(ngModel)]="model.unit_pluriels">
					</div>
				</div>
			</div>
		</div>

		<div class="col-12">
			<h3 class="mt-4">Prix de vente</h3>
			<div class="row" *ngFor="let price of model.prices">
				<div class="col-1">
					<label class="switch">
		        <input type="checkbox" [(ngModel)]="price.is_active" />
		        <span class="slider round"></span>
		      </label>
				</div>
				<div class="col-4 mb-2">
					<div class="input-group">
						<input type="number" class="form-control" [(ngModel)]="price.quantity">
						<span class="input-group-text">{{price.quantity == 1 ? model.unit : model.unit_pluriels}}</span>
					</div>
				</div>
				<div class="col-4 mb-2">
					<div class="input-group">
						<input type="number" step="0.01" class="form-control" [(ngModel)]="price.price">
						<span class="input-group-text">€ / {{model.unit}}</span>
					</div>
				</div>
				<div class="col-2 mb-2">
					<span class="badge badge-primary p-2 me-2 mt-2">{{ price.quantity * price.price - ((price.quantity * price.price) * model.vat_percent / 100)}} € HT</span>
					<span class="badge badge-primary p-2 mt-2">{{ price.quantity * price.price }} € TTC</span>
				</div>
				<div class="col-1 mb-2 text-end">
					<button [disabled]="model.prices.length == 1" class="btn btn-danger align-text-bottom" (click)="removePrice(price)"><i class="fa fa-trash"></i></button>
				</div>
			</div>
		</div>

		<div class="col-12 text-center">
			<button class="btn btn-light" (click)="addPrice()"> + Ajouter</button>
		</div>

		<div class="col-12">
			<h3 class="mt-4">Products</h3>
			<div class="row" *ngFor="let product of model.products">
				<div class="col-1">
					<label class="switch">
		        <input type="checkbox" [(ngModel)]="product.is_active" />
		        <span class="slider round"></span>
		      </label>
				</div>
				<div class="col-4 mb-2">
					<div class="form-group">
						<app-input-autocomplete [data]="tempProducts"
							label="{{ '#Search product' | dgTranslate }}"
							[clear]="false"
							(onCleared)="onCleared($event, product)"
							(handleOnChangeSearch)="onChangeSearch($event)"
							(onSelectedItem)="onSelectedItem($event, product)"></app-input-autocomplete>
					</div>
				</div>
				<div class="col-2 mb-2">
					<div class="input-group">
						<input type="number" class="form-control" [(ngModel)]="product.quantity">
						<span class="input-group-text">{{model.unit}}</span>
					</div>
				</div>
				<div class="col-2 mb-2">
					<div class="input-group">
						<input type="number" step="0.01" class="form-control" [(ngModel)]="product.price">
						<span class="input-group-text">€</span>
					</div>
				</div>
				<div class="col-2 text-center mb-2">
					<span class="badge badge-primary p-3">{{product.quantity * product.price + ((product.quantity * product.price) * model.vat_percent / 100)}} €</span>
				</div>
				<div class="col-1 mb-2 text-end">
					<button [disabled]="model.products.length == 1" class="btn btn-danger align-text-bottom" (click)="removeProduct(product)"><i class="fa fa-trash"></i></button>
				</div>
			</div>
		</div>

		<div class="col-12 text-center">
			<button class="btn btn-light" (click)="addProduct()"> + Ajouter</button>
		</div>

		<div class="col-12">
			<h3 class="mt-4">Caractèristiques</h3>
			<div class="row" *ngFor="let feature of model.features">
				<div class="col-1">
					<label class="switch">
		        <input type="checkbox" [(ngModel)]="feature.is_active" />
		        <span class="slider round"></span>
		      </label>
				</div>
				<div class="col-5 mb-2">
					<div class="input-group">
						<input type="text" placeholder="key" class="form-control" [(ngModel)]="feature.name">
					</div>
				</div>
				<div class="col-5 mb-2">
					<div class="input-group">
						<input type="text" placeholder="value" class="form-control" [(ngModel)]="feature.value">
					</div>
				</div>
				<div class="col-1 mb-2 text-end">
					<button [disabled]="model.features.length == 1" class="btn btn-danger align-text-bottom" (click)="removeFeature(feature)"><i class="fa fa-trash"></i></button>
				</div>
			</div>
		</div>

		<div class="col-12 text-center">
			<button class="btn btn-light" (click)="addFeature()"> + Ajouter</button>
		</div>
	</div>
</div>

<div class="modal-footer">
  <button class="btn btn-light me-2" (click)="cancel()">Cancel</button>
  <button class="btn btn-success" [disabled]="!model.name || isLoading" (click)="save()">Save</button>
</div>
