<div class="modal-header">
  <h4 class="modal-title float-start">
  	Choisir le produit
  	<button *ngIf="currentCategory" class="btn btn-secondary mr-2" (click)="clearCategory()"><- Retour</button>
  </h4>
  <button type="button" class="close float-end" aria-label="Close" [disabled]="isLoading" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
	<div class="container" *ngIf="!currentCategory">
		<div class="d-grid gap-3">
			<button class="btn btn-dark btn-lg" type="button" (click)="selectCategory('fleur')">Fleurs</button>
			<button class="btn btn-dark btn-lg" type="button" (click)="selectCategory('hash')">Hash/Résines</button>
			<button class="btn btn-dark btn-lg" type="button" (click)="selectCategory('eliquide')">E-Liquides/Wpuff</button>
			<button class="btn btn-dark btn-lg" type="button" (click)="selectCategory('huile')">Huiles</button>
			<button class="btn btn-dark btn-lg" type="button" (click)="selectCategory('chips')">Chips</button>
			<button class="btn btn-dark btn-lg" type="button" (click)="selectCategory('confiserie')">Confiserie</button>
			<button class="btn btn-dark btn-lg" type="button" (click)="selectCategory('puff')">Puff</button>
			<button class="btn btn-dark btn-lg" type="button" (click)="selectCategory('accessoires')">Accessoires</button>
			<button class="btn btn-dark btn-lg" type="button" (click)="selectCategory('dabpen')">Dab Pen</button>
			<button class="btn btn-dark btn-lg" type="button" (click)="selectCategory('dabpod')">Dab Pod</button>
			<button class="btn btn-dark btn-lg" type="button" (click)="selectCategory('boisson')">Boisson</button>
			<button class="btn btn-dark btn-lg" type="button" (click)="selectCategory('chocolat')">Bar Chocolat</button>
			<button class="btn btn-dark btn-lg" type="button" (click)="selectCategory('gateau')">Gateau</button>
		</div>
	</div>

	
	<div class="row" *ngIf="filteredProducts[currentCategory]">
		<div class="col-12">
			<div class="form-group mb-4 mt-4 d-flex search-box">
				<input [(ngModel)]="keyword" type="text" class="form-control flex-fill" (input)="search()" placeholder="Rechercher un produit">
				<button *ngIf="keyword.length > 0" class="btn btn-danger btn-search-clean flex-fill" (click)="reset()">&times;</button>
			</div>
		</div>
		
		<div class="col-md-4" *ngFor="let product of filteredProducts[currentCategory]">
			<div class="card mb-3 product-selection">

				<div class="card-body text-center">
					<b class="mb-2">{{ product.name }}</b> <br />
					<img class="img-fluid" [src]="getUrlFile(product.image_one)">

					<div class="d-grid gap-2 col-12 mx-auto mt-2" *ngFor="let price of product.prices">
						<button (click)="addProductToCart(product, price.quantity, price.price)" class="btn btn-primary btn-block">{{price.quantity}}{{ price.quantity > 1 ? product.unit_pluriels : product.unit}} / {{price.price}}€ [{{price.quantity * price.price}}€] </button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="modal-footer">
  <button *ngIf="currentCategory" class="btn btn-secondary mr-2" (click)="clearCategory()"><- Retour</button>
  <button class="btn btn-light mr-2" (click)="cancel()">Fermer</button>
</div>
