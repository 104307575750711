<div class="ng-autocomplete">
	<ng-autocomplete
		#auto
	  [data]="data"
	  [searchKeyword]="keyword"
	  (selected)='selectEvent($event)'
	  (inputChanged)='onChangeSearch($event)'
	  (inputFocused)='onFocused($event)'
	  (inputCleared)='onInputCleared()'
	  [itemTemplate]="itemTemplate">
	</ng-autocomplete>
	<ng-template #itemTemplate let-item>
		<div>
			<a [innerHTML]="item.name"></a>
		</div>
	</ng-template>
</div>
