import { Component, OnInit, Inject, ElementRef, HostListener, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { DOCUMENT } from '@angular/common';
import { delay } from 'rxjs/operators';
import * as $ from 'jquery';
import * as _ from 'lodash';

import { AuthService } from '../_/services/http/auth.service';
import { UserService } from '../_/services/model/user.service';
import { ResizeService } from '../_/services/ui/resize-service.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  bsModalRef: BsModalRef;

  user: any = {
    data: {},
    role: {},
    clients: []
  };

  clients: any = [];

  currentUser: any = {};
  currentUserId: any = 0;

  isSelected: boolean = false;

  sub: any = {};

  size: number = 1;
  responsiveSize: number = 768;

  showLeftMenu: boolean = false;
  showRightMenu: boolean = false;

  constructor(
    private _cdr: ChangeDetectorRef,
    private authService: AuthService,
    private userService: UserService,
    private modalService: BsModalService,
    private resizeSvc: ResizeService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private elementRef: ElementRef,
    @Inject(DOCUMENT) private _document: any) { }

  ngOnInit(): void {
    this._document.body.style.background = 'FFF';

    this.user = this.authService.getUserData();

    this.sub.onUpdate = this.userService.onUpdate.subscribe((user: any) => {
      this.user = this.authService.getUserData();
    });

    this.detectScreenSize();
  }

  ngOnDestroy(): void {
    this.sub.onUpdate && this.sub.onUpdate.unsubscribe();

    $("#menu-toggle").click(function(e) {
      e.preventDefault();
      $("#sidebar-wrapper").toggleClass("toggled");
    });
  }

  logout() {
    this.authService.logout();
  }

  @HostListener("window:resize", [])
  private onResize() {
    // this.detectScreenSize();
  }

  ngAfterViewInit() {
    // this.detectScreenSize();
  }

  toogleLeftMenu() {
    this.showLeftMenu = !this.showLeftMenu;
    if (this.showRightMenu) {
      this.showRightMenu = false;
    }
  }

  toogleRightMenu() {
    this.showRightMenu = !this.showRightMenu;
    if (this.showLeftMenu) {
      this.showLeftMenu = false;
    }
  }

  hiddenMenu() {
    this.showRightMenu = false;
    this.showLeftMenu = false;
  }

  private detectScreenSize() {
    const currentSize = this._document.body.clientWidth;

    this.size = currentSize;
    this.resizeSvc.onResize(currentSize);
  }
}
