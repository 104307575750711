import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ThermalPrintModule } from 'ng-thermal-print'; //add this line

import { CommonModule } from "@angular/common";
import { HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgxCaptchaModule } from "ngx-captcha";
import { ToastrModule } from "ngx-toastr";

import { FileUploadModule } from 'ng2-file-upload';

import { DragulaModule } from 'ng2-dragula';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { AutocompleteLibModule } from 'angular-ng-autocomplete';

import { NgxCloudinaryWidgetModule } from 'ngx-cloudinary-upload-widget';

import {
  DoorgetsTranslateModule,
  NgTranslate,
  NgTranslateAbstract,
} from "doorgets-ng-translate";

export function newNgTranslate(http: HttpClient) {
  return new NgTranslate(http, environment.assetsPath);
}

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AuthGuard } from "./auth.service";
import { JwtInterceptor } from "./_/services/http/jwt.interceptor";

import { AuthComponent } from "./auth/auth.component";
import { LoginComponent } from "./auth/login/login.component";
import { UsersComponent } from './admin/users/users.component';
import { AdminComponent } from './admin/admin.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';

import { UsersDetailComponent } from './admin/users/users-detail/users-detail.component';
import { UsersModalCreateComponent } from './admin/users/users-modal/users-modal-create/users-modal-create.component';
import { UsersModalEditComponent } from './admin/users/users-modal/users-modal-edit/users-modal-edit.component';
import { UsersModalDeleteComponent } from './admin/users/users-modal/users-modal-delete/users-modal-delete.component';
import { CaisseComponent } from './admin/caisse/caisse.component';
import { OrdersComponent } from './admin/orders/orders.component';
import { OrdersModalEditComponent } from './admin/orders/orders-modal/orders-modal-edit/orders-modal-edit.component';
import { ProductsComponent } from './admin/products/products.component';
import { TicketsComponent } from './admin/tickets/tickets.component';
import { PaginationComponent } from './_/ui/pagination/pagination.component';
import { ProductCreateComponent } from './admin/products/products-modal/product-create/product-create.component';
import { ProductDeleteComponent } from './admin/products/products-modal/product-delete/product-delete.component';
import { ProductEditComponent } from './admin/products/products-modal/product-edit/product-edit.component';
import { InputAutocompleteComponent } from './_/forms/input-autocomplete/input-autocomplete.component';
import { AddProductCaisseComponent } from './admin/caisse/add-product-caisse/add-product-caisse.component';

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    LoginComponent,
    AdminComponent,
    DashboardComponent,
    UsersComponent,
    UsersDetailComponent,
    UsersModalCreateComponent,
    UsersModalEditComponent,
    UsersModalDeleteComponent,
    CaisseComponent,
    OrdersComponent,
    OrdersModalEditComponent,
    ProductsComponent,
    TicketsComponent,
    PaginationComponent,
    ProductCreateComponent,
    ProductDeleteComponent,
    ProductEditComponent,
    InputAutocompleteComponent,
    AddProductCaisseComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgbModule,
    ModalModule.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    CommonModule,
    DoorgetsTranslateModule.forRoot({
      provide: NgTranslateAbstract,
      useFactory: newNgTranslate,
      deps: [HttpClient],
    }),
    NgxCloudinaryWidgetModule.forRoot(
        {
            cloudName:"dzfbxltzx"
        }
    ),
    NgxCaptchaModule,
    FileUploadModule,
    DragulaModule.forRoot(),
    PdfViewerModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    ThermalPrintModule,
    AutocompleteLibModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    AuthGuard,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
