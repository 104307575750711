<div class="modal-header">
  <h4 class="modal-title pull-left"><i class="fa fa-edit"></i> {{ 'Edit user' }}</h4>
  <button type="button" class="close pull-right" aria-label="Close" [disabled]="isLoading" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
	<div class="row">
		<div class="col-md-12">
			<div class="form-group">
				<label>{{ 'Role' }}</label>
				<select  [class.is-invalid]="errors.role_id" (input)="errors.role_id=false" [(ngModel)]="model.role_id" class="form-control">
		      <option value="0">{{ 'Choose...' }}</option>
		      <option *ngFor="let role of roles" [value]="role.id">{{ role.name }}</option>
		    </select>
			</div>
		</div>

		<div class="col-md-6">
		</div>

		<div class="col-md-12">
			<div class="form-group">
				<label>{{ 'Email' }}</label>
				<input placeholder="example@gmail.com" [class.is-invalid]="errors.email" (input)="errors.email=false" [(ngModel)]="model.username" type="email" autocomplete="new-password" class="form-control">
			</div>
		</div>

		<div class="col-md-6">
			<div class="form-group">
				<label>{{ 'Lastname' }}</label>
				<input placeholder="Smith" [class.is-invalid]="errors.lastname" (input)="errors.lastname=false" [(ngModel)]="model.data.lastname" type="text" autocomplete="new-password" class="form-control">
			</div>
		</div>

		<div class="col-md-6">
			<div class="form-group">
				<label>{{ 'Firstname' }}</label>
				<input placeholder="John" [class.is-invalid]="errors.firstname" (input)="errors.firstname=false" [(ngModel)]="model.data.firstname" type="text" autocomplete="new-password" class="form-control">
			</div>
		</div>
	</div>
</div>

<div class="modal-footer">
  <button class="btn btn-light mr-4" [disabled]="isLoading" (click)="cancel()">
		<i class="fa fa-close"></i> {{ 'Cancel' }}
	</button>
	<button class="btn btn-primary" [disabled]="isLoading" (click)="save()">
		<i class="fa fa-save"></i>
		{{ 'Save' }}
	</button>
</div>
