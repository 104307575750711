import { Injectable, EventEmitter } from '@angular/core';
import { HttpService } from '../../_/services/http/http.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  onListUpdated: EventEmitter<any> = new EventEmitter();
  onUserUpdated: EventEmitter<any> = new EventEmitter();

  constructor(private httpService: HttpService) {}

  getStats() {
    let page = {
      pageNumber: 0,
      size: 100,
    };

    return this.httpService.get(`stats`);
  }

}
