import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';

import { ToastrService } from 'ngx-toastr';

import { webConfig } from '../../../web-config';

import * as _ from 'lodash';

import { UsersService } from '../users.service';

import { UsersModalEditComponent } from '../users-modal/users-modal-edit/users-modal-edit.component';
import { UsersModalDeleteComponent } from '../users-modal/users-modal-delete/users-modal-delete.component';

@Component({
  selector: 'tpc-users-detail',
  templateUrl: './users-detail.component.html',
  styleUrls: ['./users-detail.component.scss']
})
export class UsersDetailComponent implements OnInit {
  bsModalRef: BsModalRef;

  activeTab: string = 'user-nav-profile';

	id: number = 0;

  model: any = {
    data: {},
  };

	sub: any = {};
  roles: any[] = [];

  subjectRoles: any;
  subjectUpdateUsers: any;

  currentProgram: any = {};
  currentProgramId: number = -1;

  isAthlete: boolean = false;
  isCoach: boolean = false;
  isLoading: boolean = false;

  constructor(
    private toastrService: ToastrService,
  	private usersService: UsersService,
    private route: ActivatedRoute,
    private modalService: BsModalService,
	) { }

  ngOnInit(): void {
  	this.sub.route = this.route.params.subscribe(params => {
      this.id = +params['id'];
      this._initUser();
    });

    this._initRoles();

    this.subjectUpdateUsers = this.usersService.onUserUpdated.subscribe(() => {
      this._initUser(true);
    });
  }

  ngOnDestroy() {
    this.subjectRoles && this.subjectRoles.unsubscribe();
    this.subjectUpdateUsers && this.subjectUpdateUsers.unsubscribe();
    this.sub.route && this.sub.route.unsubscribe();
  }

  openEditUserModal() {
    const initialState = {
      modelId: this.id,
      model: _.cloneDeep(this.model)
    };

    this.bsModalRef = this.modalService.show(UsersModalEditComponent, {
      keyboard: false,
      initialState: initialState,
      class: 'modal-xs'
    });
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    // console.log('changeEvent.nextId', changeEvent.nextId);
  }

  selectProgram(i, event?) {
    event && event.preventDefault();
    this.currentProgramId = i;
  }

  closeProgram() {
    this.currentProgramId = -1;
  }

  save() {
    this.isLoading = true;
    this.usersService.updateUser(this.id, this.model).subscribe((data: any) => {
      if (!data.errors) {
        this.toastrService.success('User updated!');
        this._initUser();
      } else {
        this.toastrService.error(data.message || 'An error has occurred');
      }
      this.isLoading = false;
    }, error => this.toastrService.error('An error has occurred'));
  }

  private _initRoles() {
    this.subjectRoles = this.usersService.getAllRoles().subscribe((roles: any) => {
      if (roles.content) {
        this.roles = roles.content;
      }
    });
  }

  private _initUser(isInit?) {
    this.isLoading = true;
    this.sub.model = this.usersService.getOne(this.id).subscribe((userData: any) => {
      this.model = userData;
      this.isLoading = false;
    });
  }
}
