import { Injectable, EventEmitter } from '@angular/core';
import { HttpService } from '../../_/services/http/http.service';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  onListUpdated: EventEmitter<any> = new EventEmitter();
  onUserUpdated: EventEmitter<any> = new EventEmitter();

  constructor(private httpService: HttpService) {}

  getAll(page?) {
    page = page || {
      totalElements: 0,
      pageNumber: 0,
      size: 10,
      query: ''
    };

    return this.httpService.get(`orders/?page=${page.pageNumber}&size=${page.size}&query=${page.query}`);
  }

  getOne(id) {
    return this.httpService.get(`orders/${id}`);
  }

  getInvoice(id) {
    return this.httpService.getHtml(`invoice/${id}`);
  }

  updateInvoice({id, delivery_status, shipping_method_link, shipping_method_code}) {
    console.log(delivery_status);
    return this.httpService.put(`invoice/${id}`, {
      delivery_status,
      shipping_method_link,
      shipping_method_code
    });
  }
}
