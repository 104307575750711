<!-- <pre>{{products|json}}</pre> -->

<div class="d-grid gap-3" *ngIf="!status" >
	<button class="btn btn-dark btn-lg" (click)="connectToPrint()">Se connecter à l'imprimante</button>
</div>
<div class="row">
	<div class="col-12" *ngIf="!isValidation">
		<h1>{{ totalArticles }} articles <span class="float-end">{{ totalGramme }} grammes</span> </h1>
		<div class="d-grid gap-3">
			<button class="btn btn-primary btn-lg" (click)="openAddProductModal()">+ Ajouter un produit</button>
		</div>
		<hr />
		<div class="row">
			<div class="col-lg-4 cart-total">
				<div class="input-group mb-3">
				  <span class="input-group-text">Prix Réél</span>
				  <input type="text" disabled="disabled" class="form-control w-25 text-right" [(ngModel)]="realTotalPrice">
				  <span class="input-group-text">€</span>
				</div>
			</div>
			<div class="col-lg-4 cart-total">
				<div class="input-group mb-3">
				  <span class="input-group-text">Prix Final</span>
				  <input type="text" disabled="disabled" class="form-control w-25 text-right" [(ngModel)]="totalPrice">
				  <span class="input-group-text">€</span>
				</div>
			</div>
			<div class="col-lg-4 cart-total">
				<div class="d-grid gap-3">
					<button class="btn btn-lg btn-secondary" (click)="isValidation=true" [disabled]="!status || !totalPrice"><i class="fa fa-print" aria-hidden="true"></i> Imprimer le ticket</button>
				</div>
			</div>
		</div>
		<hr />
		<div class="row">
			<div class="col-md-3" *ngFor="let product of cart">
				<div class="card mb-3 product-selection">
					<div class="card-header text-center">
						<b>{{ product.name }}</b>
					</div>
					<div class="card-body text-center">
						<img class="img-fluid img-product" [src]="getUrlFile(product.image_one)">
						<div class="mt-2">
							<!-- <button class="btn btn-success float-start" (click)="lessToCart(product)">-</button> -->
							<button class="btn btn-danger" (click)="delete(product)">x</button>
							<!-- <button class="btn btn-success float-end" (click)="moreToCart(product)">+</button> -->
						</div>
					</div>
					<div class="card-footer text-center">
						<div class="input-group mb-3">
						  <span class="input-group-text">{{ product.quantity * product.quantityUnit }}{{ product.unit }}</span>
						  <input type="text" class="form-control" disabled="disabled" [ngModel]="product.finalPrice">
						  <span class="input-group-text">€</span>
						</div>
						<select class="mb-3" [(ngModel)]="product.quantityUnit" (ngModelChange)="updateProductQuantiry(product)">
							<option *ngFor="let pos of quantities; let c = index" value="{{c+1}}">{{c+1}}</option>
						</select>
						<div class="input-group mb-3">
						  <input type="text" class="form-control" [disabled]="product.manualPrice == -1" (input)="calcul()" [(ngModel)]="product.manualPrice">
						  <span class="input-group-text">€</span>
						</div>
						<button *ngIf="product.manualPrice != -1" class="btn btn-light col-12" (click)="product.manualPrice=-1;calcul()" >
							Gratuit
						</button>
						<button *ngIf="product.manualPrice == -1" class="btn btn-light col-12" (click)="product.manualPrice=0;calcul()" >
							Reset
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-12" *ngIf="isValidation">
		<div class="card">
			<div class="card-body">
				<p class="alert alert-info mt-4 mb-4 text-danger text-center"> <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Attention vous êtes sur le point de valider la commande <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> <br /> <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> L'information sera envoyé au serveur <i class="fa fa-exclamation-triangle" aria-hidden="true"></i></p>
				<p class="alert alert-light text-center p-4 final-price-resume">{{ totalPrice }} €</p>
				<p *ngIf="isPrinted text-center">Impression en cours...</p>

				<div class="row">
					<div class="col-6">
						<div class="form-group mb-4">
							<label>Email</label>
							<input type="text" class="form-control" [(ngModel)]="email">
						</div>
					</div>
					<div class="col-6">
						<div class="form-group mb-4">
							<label>Phone</label>
							<input type="text" class="form-control" [(ngModel)]="phone">
						</div>
					</div>
				</div>


				<div class="form-group mb-4">
					<label>Type de paiement</label>
					<select class="form-control" [(ngModel)]="paymentType">
						<option value="CB">Carte bancaire</option>
						<option value="ESPECES">Espèces</option>
					</select>
				</div>


				<div class="row">
					<div class="col-6">
						<button [disabled]="isPrinted" class="btn btn-success btn-lg col-12" (click)="print()"><i class="fa fa-print" aria-hidden="true"></i> IMPRIMER MAINTENANT</button>
					</div>
					<div class="col-6">
						<button [disabled]="isPrinted" (click)="isValidation=false" class="btn btn-danger btn-lg col-12"><i class="fa fa-times-circle-o" aria-hidden="true"></i> ANNULER</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>


<!-- <strong>Status: {{status}}</strong>
<div class="mb-4">
    <input [(ngModel)]="ip" type="text" name="ip" placeholder="IP of printer with WebPRNT">
    <button (click)="connectToWebPrint()">Connect to WebPRNT</button>
</div>

 -->
