<button class="btn btn-primary float-end" (click)="openCreateUserModal()">
	<i class="fa fa-plus"></i> {{ 'New client' }}
</button>

<h2 class="title-rubrique"><i class="fa fa-suitcase"></i> {{ page.totalElements }} {{ 'Clients' }}</h2>

<div class="row mb-4">
  <div class="col-md-10">
    <button class="btn btn-query-clear" *ngIf="query" (click)="clear()"><i class="fa fa-close"></i> {{ 'clear' }}</button>
    <input class="form-control" [(ngModel)]="query" placeholder="#Search" type="text" autocomplete="new-password">
  </div>
  <div class="col-md-2 text-right">
    <button class="btn btn-light" (click)="search()"><i class="fa fa-search"></i> {{ 'Search' }}</button>
  </div>
</div>


<div class="row">
  <div class="col-12">
    <div class="container-fluid mt-4 mb-4" *ngIf="!isLoading && page.size < page.currentTotalElements">
      <div class="row">
        <div class="col-12 text-center">
          <nav class=" mt-4" *ngIf="users.length" aria-label="...">
            <ul class="pagination pagination-lg justify-content-center">
              <li class="page-item" [class.disabled]="page.pageNumber == 1">
                <button class="page-link" (click)="setFirstPage()">{{ '#First' | dgTranslate }}</button>
              </li>
              <li class="page-item" [class.disabled]="page.pageNumber == 1">
                <button class="page-link" (click)="previousPage()">{{ '#Previous' | dgTranslate }}</button>
              </li>
              <li class="page-item" *ngIf="page.pageNumber - 2 >= 1">
                <button class="page-link" (click)="setPositionPage(page.pageNumber - 2)">{{ page.pageNumber - 2 }}</button>
              </li>
              <li class="page-item" *ngIf="page.pageNumber - 1 >= 1 ">
                <button class="page-link" (click)="setPositionPage(page.pageNumber - 1)">{{ page.pageNumber - 1 }}</button>
              </li>
              <li class="page-item active">
                <button class="page-link">{{ page.pageNumber }}</button>
              </li>
              <li class="page-item" *ngIf="page.totalPage - 1 >= page.pageNumber">
                <button class="page-link" (click)="setPositionPage(page.pageNumber + 1)">{{ page.pageNumber + 1 }}</button>
              </li>
              <li class="page-item" *ngIf="page.totalPage - 2 >= page.pageNumber">
                <button class="page-link" (click)="setPositionPage(page.pageNumber + 2)">{{ page.pageNumber + 2 }}</button>
              </li>
              <li class="page-item" [class.disabled]="page.pageNumber == page.totalPage || page.totalPage - page.pageNumber - 2 < 1">
                <button class="page-link" (click)="nextPage()">{{ '#Next' | dgTranslate }}</button>
              </li>
              <li class="page-item" [class.disabled]="page.pageNumber == page.totalPage || page.totalPage - page.pageNumber - 2 < 1">
                <button class="page-link" (click)="setLastPage()">{{ '#Last' | dgTranslate }} {{page.totalPage}}</button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12" *ngFor="let user of users">
    <div class="card mb-4">
      <div class="card-body">
        <span class="float-end">
          <button class="btn btn-light" routerLink="{{ user.id }}"><i class="fa fa-pencil"></i></button>
          <button class="btn btn-danger ml-2" (click)="removeUser(user)"><i class="fa fa-trash"></i></button>
          <button class="btn btn-light ml-2" (click)="connectAs(user)"><i class="fa fa-external-link"></i></button>
        </span>
        <h3 class="card-title">{{ user.first_name }} {{ user.last_name }}</h3>
        <p><i class="fa fa-email"></i> {{ user.email }}</p>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="container-fluid mt-4 mb-4" *ngIf="!isLoading && page.size < page.currentTotalElements">
      <div class="row">
        <div class="col-12 text-center">
          <nav class=" mt-4" *ngIf="users.length" aria-label="...">
            <ul class="pagination pagination-lg justify-content-center">
              <li class="page-item" [class.disabled]="page.pageNumber == 1">
                <button class="page-link" (click)="setFirstPage()">{{ '#First' | dgTranslate }}</button>
              </li>
              <li class="page-item" [class.disabled]="page.pageNumber == 1">
                <button class="page-link" (click)="previousPage()">{{ '#Previous' | dgTranslate }}</button>
              </li>
               <li class="page-item" *ngIf="page.pageNumber - 2 >= 1">
                <button class="page-link" (click)="setPositionPage(page.pageNumber - 2)">{{ page.pageNumber - 2 }}</button>
              </li>
              <li class="page-item" *ngIf="page.pageNumber - 1 >= 1 ">
                <button class="page-link" (click)="setPositionPage(page.pageNumber - 1)">{{ page.pageNumber - 1 }}</button>
              </li>
              <li class="page-item active">
                <button class="page-link">{{ page.pageNumber }}</button>
              </li>
              <li class="page-item" *ngIf="page.totalPage - 1 >= page.pageNumber">
                <button class="page-link" (click)="setPositionPage(page.pageNumber + 1)">{{ page.pageNumber + 1 }}</button>
              </li>
              <li class="page-item" *ngIf="page.totalPage - 2 >= page.pageNumber">
                <button class="page-link" (click)="setPositionPage(page.pageNumber + 2)">{{ page.pageNumber + 2 }}</button>
              </li>
              <li class="page-item" [class.disabled]="page.pageNumber == page.totalPage || page.totalPage - page.pageNumber - 2 < 1">
                <button class="page-link" (click)="nextPage()">{{ '#Next' | dgTranslate }}</button>
              </li>
              <li class="page-item" [class.disabled]="page.pageNumber == page.totalPage || page.totalPage - page.pageNumber - 2 < 1">
                <button class="page-link" (click)="setLastPage()">{{ '#Last' | dgTranslate }} {{page.totalPage}}</button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isLoading"  class="spinner-ctnr">
  <div class="logo-fixed"></div>
  <div class="spinner colorwheel">
  <div class="centerpiece"></div>
</div>
