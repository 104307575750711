<div class="modal-header">
  <h4 class="modal-title float-start">
  	Create new product
  </h4>
  <button type="button" class="close float-end" aria-label="Close" [disabled]="isLoading" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
	<div class="row">
		<div class="col-12 mb-2">
			<div class="form-group">
				<label>Type</label>
				<select class="form-control" name="type" [(ngModel)]="model.type">
					<option value="simple">Simple</option>
					<option value="grouped">Groupé</option>
					<option value="configurable">Configurable</option>
					<option value="virtuel">Virtuel</option>
				</select>
			</div>
		</div>
		<div class="col-12 mb-2">
			<div class="form-group">
				<label>Name</label>
				<input type="text" class="form-control" [(ngModel)]="model.name">
			</div>
		</div>
	</div>
</div>

<div class="modal-footer">
  <button class="btn btn-light mr-2" (click)="cancel()">Cancel</button>
  <button class="btn btn-success" (click)="save()" [disabled]="!model.name || isLoading">Save</button>
</div>
