<div class="modal-header">
  <h4 class="modal-title pull-left">
  	<i class="fa fa-calendar"></i> {{ model.created_at.date | date }} <span *ngIf="model.delivery_status">- {{ model.delivery_status }}</span>
  </h4>
  <button type="button" class="close pull-right" aria-label="Close" [disabled]="isLoading" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
	<h3 class="h3 font-weight-normal">
		<i class="mt-4 fas fa-receipt fa-1x"></i> Commande n° {{ model.cart_id }} <br />
	</h3>

	<hr />

	<p class="form-group">
		<label>Statut</label>
		<select class="form-control" [(ngModel)]="model.delivery_status">
			<option *ngFor="let st of status">{{st}}</option>
		</select>
	</p>

	<div class="card mt-3">
		<div class="card-body">
			<p>Code de suivi: <input class="form-control" [(ngModel)]="model.shipping_method_code"></p>
			Lien de suivi: <a href="https://www.laposte.fr/outils/suivre-vos-envois?code={{ model.shipping_method_code }}" target="self">https://www.laposte.fr/outils/suivre-vos-envois?code={{ model.shipping_method_code }}</a>
		</div>
	</div>

	<div *ngIf="model.firstname" class="card mt-3 mb-4">
		<div class="card-body">
			<div class="row">
				<div class="col-md-6">
					<b>Adresse de livraison</b>
					<p class="pl-2">
						{{ model.firstname }} {{ model.lastname }}<br />
						{{ model.street }}<br />
						{{ model.zip_code }}  {{ model.city }} {{ model.country }} <br />
						{{ model.phone }} <br />
						{{ model.extra }}
					</p>
				</div>
				<div class="col-md-6">
					<b>Adresse de facturation</b>
					<p class="pl-2">
						{{ model.billing_firstname }} {{ model.billing_fastname }}<br />
						{{ model.billing_street }}<br />
						{{ model.billing_zip_code }}  {{ model.billing_city }} {{ model.billing_country }} <br />
						{{ model.billing_phone }} <br />
						{{ model.billing_extra }}
					</p>
				</div>
			</div>
		</div>
	</div>

	<h3 class="h3 font-weight-normal"><i class="fa fa-shopping-basket"></i> {{  model.cart_products && model.cart_products.length }} article{{  model.cart_products && model.cart_products.length > 1 ? 's' : '' }}</h3>

	<div *ngFor="let product of model.cart_products" class="card mt-3">
		<div class="card-body">
			<div class="row">
				<div class="col-2"><img class="img-fluid" width="50px" src="{{ product.image }}" /></div>
				<div class="col-5">
					<b>{{ product.name }}</b> <br />
					<span class="text-muted">{{ product.price }}€ / {{ product.gramme }} Gramme{{ product.gramme > 1 ? 's' : ''}}</span>
				</div>
				<div class="col-2">
					{{ product.gramme * product.quantity }}G
				</div>
				<div class="col-2 text-right"><span class="text-nowrap">{{ (product.price * product.quantity) }} €</span></div>
			</div>
		</div>
	</div>

	<div *ngIf="model.promo_code" class="card mt-3">
		<div class="card-body">
			Code promo: <b>{{ model.promo_code }}</b>
			<p>{{ model.promo_description }}</p>
		</div>
	</div>

	<div class="card mt-4 sticky-top">
		<div class="card-body ">
			<div *ngIf="model.promo_code" class="mt-2">Promo <span class="float-end font-weight-normal">{{ (model.promo_code ? -model.promo_amount : 0.00) }}</span></div>
			<div class="mt-2">TVA (20%) incluse <span class="float-end font-weight-normal">{{ (model.promo_code ? model.vat_total_discount : model.vat_total ) }} €</span></div>
			<div class="mb-4">Livraison <span class="float-end font-weight-normal">{{ model.shipping_amount }} €</span></div>

			<div class="border-top border-bottom border-left border-right p-2 bg-light text-success">
				<h4>Total <span class="float-end font-weight-normal">{{ (model.promo_code ? model.price_total_discount : model.price_total) }} €</span></h4>
			</div>
		</div>

		<div class="col-12 p-4 align-center">
			<button class="btn btn-success" (click)="updateInvoice()">Save</button>
		</div>
	</div>

	<div *ngIf="isLoading"  class="spinner-ctnr">
		<div class="logo-fixed"></div>
		<div class="spinner colorwheel">
		<div class="centerpiece"></div>
	</div>
</div>
